import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import { ASSETTYPE } from "../../../constant/inspectionConstant";
import Carousel from "../../../library/custom/carouselBox/Carousel";
import NORow from "../../../library/custom/noRow/noRow";
import InspectionCommonForm from "./inspectionCommonForm";
import Buttons from "../../../library/custom/button/Button";
import Categorys from "./categorys";
import {
  mapListInspectionCategory,
  validateCategories,
} from "../../../utils/incpectionHelperNew";
import { SIGNOFF_METHOD_TYPE } from "../../../constant/constant";
import commonImages from "../../../assets";
import AddIcon from "@mui/icons-material/Add";
import _ from "lodash";
import { getFormatedDate } from "../../../utils/helper";
import "./addInspection.scss";
const AddInspection = (props) => {
  const hiddenSignatureInput = useRef(null);
  const [acknowledgementError, setAcknowledgementError] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);
  const [certifyStatement, setCertifyStatement] = useState(false);
  const [assetError, setAssetError] = useState(false);
  const [inspectionDateError, setInspectionDateError] = useState(false);
  const [inspectionTimeError, setInspectionTimeError] = useState(false);

  function mappedData() {
    const listInspectionCategoryClone = [...props?.listInspectionCategory];
    const isError = validateCategories(listInspectionCategoryClone);
    props?.setListInspectionCategory(listInspectionCategoryClone);
    let error = false;

    if (!props?.assets) {
      setAssetError(true);
      error = true;
    } else if (!props?.inspectionDate) {
      setInspectionDateError(true);
      error = true;
    } else if (!props?.inspectionTime) {
      setInspectionTimeError(true);
      error = true;
    } else if (
      props?.signoffMethodValue === SIGNOFF_METHOD_TYPE.CERTIFY_STATEMENT ||
      props?.signoffMethodValue === 4
    ) {
      if (!certifyStatement) {
        setAcknowledgementError(true);
        error = true;
      } else {
        setAcknowledgementError(false);
        error = false;
      }
    } else if (
      props?.signoffMethodValue === SIGNOFF_METHOD_TYPE.SIGNATURE ||
      props?.signoffMethodValue === 4
    ) {
      if (!props?.signatureUrl) {
        props?.setSignatureError(true);
        error = true;
      } else {
        props?.setSignatureError(false);
        error = false;
      }
    } else {
      setAssetError(false);
      setInspectionDateError(false);
      setInspectionTimeError(false);
      error = false;
    }
    if (props?.validateAllFields() && !isError && !error) {
      const data = {
        inspectionDate: getFormatedDate(props?.inspectionDate),
        inspectionTime: props?.inspectionTime,
        inspectionId: props?.inspectionId ? props?.inspectionId : 0,
        questionSetId: parseInt(props?.commonFormValue?.questionSetId),
        assetId: props?.assets?.key,
        status: 5,
        preparedBy: parseInt(props?.commonFormValue?.preparedBy),
        assetType: parseInt(props?.commonFormValue?.assetType),
        signature: props?.signatureUrl,
        signOffMethodType: props?.signoffMethodValue,
        inspectionHeaderFieldAns: props?.inspectionFieldsMap?.map((item) => {
          const updatedItem = {
            insTypeFieldMappingId: item.insTypeFieldMappingId,
            insTypeFieldMappingVerId: item.insTypeFieldMappingVerId,
            insTypeFieldVerId: item.insTypeFieldVerId,
            insTypeFieldId: item.key,
            ans: item.ans || null,
          };
          return updatedItem;
        }),
        certifyStatementId: props?.certifyStatementIdValue,
      };
      return data;
    } else {
      console.log("Validation failed");
    }
  }
  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      if (data?.inspectionId === 0) {
        const newCategoryes = JSON.parse(
          JSON.stringify(props?.listInspectionCategory)
        );
        const mappedCategories = mapListInspectionCategory(newCategoryes);
        data.listInspectionCategory = mappedCategories;
        props?.onSubmit(data);
      } else {
        const newCategoryes = JSON.parse(
          JSON.stringify(props?.listInspectionCategory)
        );
        const mappedCategories = mapListInspectionCategory(newCategoryes);
        data.listInspectionCategory = mappedCategories;
        props?.onSubmit(data);
      }
    }
  };

  const saveData = () => {
    let error = false;

    if (
      props?.signoffMethodValue === SIGNOFF_METHOD_TYPE.CERTIFY_STATEMENT ||
      props?.signoffMethodValue === 4
    ) {
      if (!certifyStatement) {
        setAcknowledgementError(true);
        error = true;
      } else {
        setAcknowledgementError(false);
        error = false;
      }
    }
    if (!props?.assets) {
      setAssetError(true);
      error = true;
    }

    if (!error) {
      const formData = {
        inspectionDate: getFormatedDate(props?.inspectionDate),
        inspectionTime: props?.inspectionTime,
        inspectionId: props?.inspectionId ? props?.inspectionId : 0,
        questionSetId: parseInt(props?.commonFormValue?.questionSetId),
        status: 1,
        assetId: props?.assets?.key,
        preparedBy: parseInt(props?.commonFormValue?.preparedBy),
        assetType: parseInt(props?.commonFormValue?.assetType),
        signature: props?.signatureUrl,
        signOffMethodType: props?.signoffMethodValue,
        inspectionHeaderFieldAns: props?.inspectionFieldsMap?.map((item) => {
          const updatedItem = {
            insTypeFieldMappingId: item.insTypeFieldMappingId,
            insTypeFieldMappingVerId: item.insTypeFieldMappingVerId,
            insTypeFieldVerId: item.insTypeFieldVerId,
            insTypeFieldId: item.key,
            ans: item.ans || null,
          };
          return updatedItem;
        }),
        certifyStatementId: props?.certifyStatementIdValue,
      };
      const newCategoryes = JSON.parse(
        JSON.stringify(props?.listInspectionCategory)
      );
      const mappedCategories = mapListInspectionCategory(newCategoryes);
      formData.listInspectionCategory = mappedCategories;
      props?.onSubmit(formData);
    }
  };
console.log('deploy');

  useEffect(() => {
    if (props?.inspectionAcknowledgement && props?.certifyStatementIdValue) {
      const findInspectionAcknowledgement =
        props?.inspectionAcknowledgement?.find(
          (item) => item.key === props?.certifyStatementIdValue
        );
      props?.setAcknowledgement(
        findInspectionAcknowledgement?.description ||
          findInspectionAcknowledgement?.value
      );
    }
  }, [props?.inspectionAcknowledgement, props?.certifyStatementIdValue]);

  return (
    <>
      <Grid container>
        <Grid md={4} xs={12} item>
          <FormControl fullWidth>
            <FormLabels label="Asset Type" isRequired={true} />
            <SelectMenu
              placeholder="Please Select"
              listData={ASSETTYPE}
              value={props?.assetTypeValue ? props?.assetTypeValue : ""}
              onchangehandler={(e) => props?.handleAssetChange(e)}
              disabled={props?.editMode ? true : false}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container>
        <Grid md={12} xs={12} item>
          {props?.sliderItems.length === 0 ? (
            <NORow />
          ) : (
            <>
              {_.isNumber(props?.assetTypeValue) &&
                !_.isNaN(props?.assetTypeValue) &&
                !_.isUndefined(props?.assetTypeValue) && (
                  <>
                    <Carousel
                      sliderItems={props?.sliderItems}
                      activeSlide={props?.activeSlide}
                      handleOnChange={props?.quetionSetChange}
                      edit={props?.editMode}
                    />
                    {props?.activeSlide?.id && (
                      <>
                        <Grid container mt={5}>
                          <Grid md={12} xs={12} item>
                            <InspectionCommonForm
                              inspectionFieldsMap={props?.inspectionFieldsMap}
                              assetTypeValue={props?.assetTypeValue}
                              assetOption={props?.assetOption}
                              isAssetLoading={props?.isAssetLoading}
                              searchAsset={props?.searchAsset}
                              commonFormValue={props?.commonFormValue}
                              assets={props?.assets}
                              handleInputChange={props?.handleInputChange}
                              inputFieldValues={props?.inputFieldValues}
                              handleFieldChange={props?.handleFieldChange}
                              fieldValues={props?.fieldValues}
                              validationErrors={props?.validationErrors}
                              allAsset={props?.allAsset}
                              HBNUser={props?.HBNUser}
                              handleAssetOnChange={props?.handleAssetOnChange}
                              handleSelectOnChange={props?.handleSelectOnChange}
                              assetError={assetError}
                              inspectionDateError={inspectionDateError}
                              inspectionTimeError={inspectionTimeError}
                              handleTimeHandler={props?.handleTimeHandler}
                              handleDateHandler={props?.handleDateHandler}
                              editMode={props?.editMode}
                              inspectionTime={props?.inspectionTime}
                              inspectionDate={props?.inspectionDate}
                              statusId={props?.statusId}
                              weather={props?.weather}
                            />
                          </Grid>
                        </Grid>

                        <Grid container mt={3}>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            className="swAddReport saftyReport"
                          >
                            <Categorys
                              listInspectionCategory={
                                props?.listInspectionCategory
                              }
                              setListInspectionCategory={
                                props?.setListInspectionCategory
                              }
                              handleFormChange={props?.handleFormChange}
                              isAutoSearchUserLoading={
                                props?.isAutoSearchUserLoading
                              }
                              handleAllUserSearch={props?.handleAllUserSearch}
                              allUsers={props?.allUsers}
                              setImage={props?.setImage}
                              deleteImageURLS={props?.deleteImageURLS}
                              assets={props?.assets}
                            />
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item md={12} xs={12}>
                            {(parseInt(props?.signoffMethodValue) ===
                              SIGNOFF_METHOD_TYPE.CERTIFY_STATEMENT ||
                              parseInt(props?.signoffMethodValue) === 4) && (
                              <>
                                <FormControl fullWidth>
                                  <div className="typeTxt">
                                    <img
                                      className="checkImage"
                                      title={
                                        certifyStatement
                                          ? "tickCheckbox"
                                          : "untickCheckbox"
                                      }
                                      alt=""
                                      src={
                                        certifyStatement
                                          ? commonImages.check
                                          : commonImages.noCheck
                                      }
                                      onClick={() =>
                                        setCertifyStatement(!certifyStatement)
                                      }
                                    />
                                    <span className="associatedIncidentLabel ml-1">
                                      {props?.acknowledgement}
                                    </span>
                                  </div>
                                </FormControl>
                                {acknowledgementError && (
                                  <p className="error">
                                    Please check acknowledgement
                                  </p>
                                )}
                              </>
                            )}

                            {(parseInt(props?.signoffMethodValue) ===
                              SIGNOFF_METHOD_TYPE.SIGNATURE ||
                              parseInt(props?.signoffMethodValue) === 4) && (
                              <>
                                <Grid container mb={3} mt={3}>
                                  {props?.signatureurlValue !== "null" ? (
                                    <Grid item md={12}>
                                      <img
                                        src={props?.signatureurlValue}
                                        width={"200px"}
                                        alt="inspector signature"
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid item md={12} mt={2}>
                                      <FormLabels label="Add Signature" />

                                      <input
                                        type="file"
                                        ref={hiddenSignatureInput}
                                        onChange={(event) => {
                                          props?.inspectorsignatureUpload(
                                            event
                                          );
                                        }}
                                        style={{ display: "none" }}
                                        accept="image/*"
                                      />
                                      <label
                                        className="signatureBtn alignCenter"
                                        onClick={() => {
                                          hiddenSignatureInput.current.click();
                                        }}
                                        htmlFor="actual-btn"
                                      >
                                        <AddIcon /> Add Signature
                                      </label>

                                      <Grid item lg={12} xs={12} mt={1}>
                                        {!props?.signatureUrl &&
                                          props?.signatureError && (
                                            <p className="error">
                                              Please Add Signature
                                            </p>
                                          )}
                                      </Grid>

                                      {props?.signaureFileError && (
                                        <div className="error">
                                          {props?.signaureFileErrorMessage}
                                        </div>
                                      )}

                                      {props?.inspectorSignUpload && (
                                        <img
                                          width={"200px"}
                                          src={props?.signatureUrl}
                                          alt="inspector signature"
                                        />
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>

                        <Grid container mt={7} className="buttonEnd">
                          <Grid item lg={4} xs={12} sm={4} md={4}></Grid>
                          <Grid item lg={4} xs={12} sm={4} md={4}>
                            <Buttons
                              varientText={true}
                              label="Save As Draft"
                              onClick={saveData}
                            />
                          </Grid>
                          <Grid item lg={4} xs={12} sm={4} md={4}>
                            <Buttons
                              varientContained={true}
                              label={"Save"}
                              onClick={submitData}
                              // disabled={isSubmit ? true : false}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AddInspection;
