import "./dashboard.scss";
import { useEffect, useState, Suspense, lazy } from "react";
import {
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
  API_STATUS,
  SERVICE_TYPE,
} from "../../constant/constant";
import { useNavigate } from "react-router-dom";
import * as supportAction from "../../redux/actions/supportAction";
import * as getDashboardAction from "../../redux/actions/dashboardAction";
import * as jhaAction from "../../redux/actions/jhaAction";
import locaibrary from "../../assets/card/assignment.svg";
import customizeDashIcon from "../../assets/info.svg";
import {
  ADMIN_RESOURCE,
  CREDENTIAL_LOOKUP,
  OPEN_ITEMS_LIST,
  SWDASHBOARD,
  USERCOURSES,
  LANDING_PAGE,
  ADMIN_ADD_NEWS,
  ADMIN_LIST_NEWS,
  TOOL_BOX_USER,
} from "../../constant/routeContant";
import Loader, { InComponentLoader } from "../../library/common/Loader";
import DonoughtChart from "../support/component/ticket/charts";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from "../../library/custom/button/Button";
import JHADashboardTable from "./jhaDashboardTable";
import { PRODUCT_ID } from "../../constant/storm-water/constant";
import * as newsAction from "../../redux/actions/newsAction";
import { checkPrivileges } from "../../utils/rolesHelper";
import { useThemeContext } from "../../context";
const NewsList = lazy(() => import("../admin/component/news/listNews"));

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dashcount, setDashcount] = useState("");
  const [dashcountfinal, setDashcountfinal] = useState("");
  const [columns, setColumns] = useState("");
  const [finalobject, setFinalobject] = useState("");
  const [dashcountfinalcardIds, setDashcountfinalcardIds] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [showJhaDashboardTable, setShowJhaDashboardTable] = useState(false);
  const { mode } = useThemeContext();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userInfoProduct = userInfo?.product;
  const data = userInfoProduct?.map((val) => val?.key);
  const getNewsList = useSelector((state) => state.getNewsList);
  const NewsData = getNewsList?.data?.news;
  useEffect(() => {
    if (PRODUCT_ID() == 2) {
      navigate(SWDASHBOARD);
    }
    if (SERVICE_TYPE() == null && data?.length > 1) {
      navigate(LANDING_PAGE);
    }
  }, []);

  useEffect(() => {
    const defaultData = {
      page: 1,
      pageSize: 2,
    };
    dispatch(newsAction.getNewsList(defaultData));
  }, []);

  const defaulydata1 = {
    userId: parseInt(USER_ID()),
    organizationId: ORG_ID(),
  };
  useEffect(() => {
    dispatch(supportAction.getDashboardTickets(""));
    dispatch(
      jhaAction.getJHASetupDashboard({
        page: 1,
        pageSize: PAGE_SIZE,
      })
    );
    dispatch(getDashboardAction.getDashboardCartList());
    dispatch(getDashboardAction.getUserDashboardCartList(defaulydata1));
  }, []);
  const getDashboardCartListStatus = useSelector(
    (state) => state.getDashboardCartList
  );
  const getJhaSetupDashboardData = useSelector(
    (state) => state.getJhaSetupDashboard
  );
  const UserDashboardCartList = useSelector(
    (state) => state.getUserDashboardCartList
  );
  const UserDashboardCartListIsloading = UserDashboardCartList?.isLoading;
  useEffect(() => {
    if (
      UserDashboardCartList.status == API_STATUS.SUCCESS &&
      UserDashboardCartList
    ) {
      var dataResult1 = UserDashboardCartList?.data?.result;
      dataResult1?.forEach((v) => (v.cartId += ""));
      var Useridsdash = dataResult1?.map((val) => {
        return val.cartId;
      });
      setDashcountfinalcardIds(Useridsdash);

      setDashcountfinal(dataResult1);
    }
  }, [UserDashboardCartList]);

  useEffect(() => {
    if (
      getDashboardCartListStatus.status == API_STATUS.SUCCESS &&
      UserDashboardCartList.status == API_STATUS.SUCCESS
    ) {
      let dataResult = getDashboardCartListStatus?.data?.result;
      dataResult?.forEach((v) => (v.cartId += ""));
      const final = dataResult?.filter(
        (val) => !dashcountfinalcardIds?.includes(val?.cartId)
      );
      setDashcount(final);
    }
  }, [getDashboardCartListStatus, dashcountfinalcardIds]);

  const dashboardTickets = useSelector(
    (state) => state.getDashboardTickets?.data?.result
  );
  const UpdateconfigDashboard = useSelector(
    (state) => state.updateUserDashboardCart
  );
  const isLoadingD = UpdateconfigDashboard?.isLoading;
  useEffect(() => {
    if (UpdateconfigDashboard?.status == API_STATUS.SUCCESS) {
      setIsVisible(false);
      dispatch(getDashboardAction.getUserDashboardCartList(defaulydata1));
    }
  }, [UpdateconfigDashboard]);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;

    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];

      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      destItems.map((key, index) => {
        return (key.cartSequenceId = index);
      });

      sourceItems.map((key, index) => {
        return (key.cartSequenceId = index);
      });
      setFinalobject(source.droppableId == "123" ? destItems : sourceItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      copiedItems.map((key, index) => {
        return (key.cartSequenceId = index);
      });
      setFinalobject(copiedItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };
  useEffect(() => {
    if (dashcount?.length > 0 || dashcountfinal?.length > 0) {
      let columnsFromBackend = {
        [111]: {
          name: "Final",
          items: dashcountfinal?.length > 0 ? dashcountfinal : [],
        },
        [123]: {
          name: "TODO",
          items: dashcount?.length > 0 && isVisible ? dashcount : [],
        },
      };
      setColumns(columnsFromBackend);
    }
  }, [dashcount, isVisible]);
  const handlesubmit = () => {
    finalobject?.map((val, index) => {
      return (val.cartId = parseInt(val.cartId));
    });
    const data = {
      userId: parseInt(USER_ID()),
      createdBy: parseInt(USER_ID()),
      createdByApp: 0,
      dashboardCart: finalobject,
    };

    updatedashboardconfig(data);
  };

  const updatedashboardconfig = async (data) => {
    dispatch(getDashboardAction.updateUserDashboardCart(data));
  };

  const custmizebtnFun = () => {
    if (isVisible) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const handleCardClick = (itemId) => {
    if (itemId === "1") {
      navigate(CREDENTIAL_LOOKUP);
    } else if (itemId === "2") {
      navigate(OPEN_ITEMS_LIST);
    } else if (itemId === "3") {
      navigate(USERCOURSES);
    } else if (itemId === "5") {
      navigate(TOOL_BOX_USER);
    } else {
      navigate(ADMIN_RESOURCE);
    }
  };
  const [isDashboard, setIsDashboard] = useState(false);
  useEffect(() => {
    if (isDashboard == true) {
      navigate(SWDASHBOARD);
    }
  }, [isDashboard]);
  const createNews = () => {
    navigate(ADMIN_ADD_NEWS);
  };
  const listNews = () => {
    navigate(ADMIN_LIST_NEWS);
  };

  return (
    <>
      {isLoading || UserDashboardCartListIsloading || isLoadingD ? (
        <Loader />
      ) : (
        <section className="grid_main_sec">
          <div className="grid_main_body">
            <div className="commingsoon">
              <div className="headingcustomige">
                <h1 className="heading" div>
                  My Favorites
                </h1>

                <div onClick={custmizebtnFun} className="customizedash">
                  <p>Customize your dashboard</p>
                  <svg
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.08334 14.0833H10.9167V8.58331H9.08334V14.0833ZM10 6.74998C10.2597 6.74998 10.4776 6.66198 10.6536 6.48598C10.829 6.31059 10.9167 6.09303 10.9167 5.83331C10.9167 5.57359 10.829 5.35573 10.6536 5.17973C10.4776 5.00434 10.2597 4.91665 10 4.91665C9.74029 4.91665 9.52273 5.00434 9.34734 5.17973C9.17134 5.35573 9.08334 5.57359 9.08334 5.83331C9.08334 6.09303 9.17134 6.31059 9.34734 6.48598C9.52273 6.66198 9.74029 6.74998 10 6.74998ZM10 18.6666C8.73195 18.6666 7.54029 18.4259 6.42501 17.9443C5.30973 17.4634 4.33959 16.8104 3.51459 15.9854C2.68959 15.1604 2.03662 14.1903 1.55568 13.075C1.07412 11.9597 0.833344 10.768 0.833344 9.49998C0.833344 8.23192 1.07412 7.04026 1.55568 5.92498C2.03662 4.8097 2.68959 3.83956 3.51459 3.01456C4.33959 2.18956 5.30973 1.53629 6.42501 1.05473C7.54029 0.573785 8.73195 0.333313 10 0.333313C11.2681 0.333313 12.4597 0.573785 13.575 1.05473C14.6903 1.53629 15.6604 2.18956 16.4854 3.01456C17.3104 3.83956 17.9634 4.8097 18.4443 5.92498C18.9259 7.04026 19.1667 8.23192 19.1667 9.49998C19.1667 10.768 18.9259 11.9597 18.4443 13.075C17.9634 14.1903 17.3104 15.1604 16.4854 15.9854C15.6604 16.8104 14.6903 17.4634 13.575 17.9443C12.4597 18.4259 11.2681 18.6666 10 18.6666Z"
                      fill={mode === "dark" ? `#fff` : "#666666"}
                    />
                  </svg>
                </div>
              </div>
              <>
                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                  {Object.entries(columns).map(([id, columns]) => {
                    return (
                      <div key={id}>
                        {columns.name == "TODO" && isVisible ? (
                          <>
                            <div className="hrcard"></div>
                            <div className="allcards">
                              <p>All Cards</p>
                            </div>
                          </>
                        ) : (
                          <div></div>
                        )}
                        <Droppable
                          type="group"
                          className="dashboardBody1"
                          droppableId={id}
                          key={id}
                        >
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="dashboardBody1"
                            >
                              {columns.name == "Final" && (
                                <Grid item container lg={2.8}>
                                  {!dashboardTickets ? (
                                    <InComponentLoader
                                      style={{
                                        position: "relative",
                                        top: "50%",
                                        left: "50%",
                                      }}
                                    />
                                  ) : (
                                    <div className="graphCardBody">
                                      <DonoughtChart
                                        DonutChart
                                        dashboardTickets={dashboardTickets}
                                        mode={mode}
                                      />
                                      <div className="assignedDiv">
                                        <h1>
                                          {dashboardTickets.unassignedTickets
                                            ? Math.round(
                                              ((dashboardTickets.totalTickets -
                                                dashboardTickets.unassignedTickets) /
                                                dashboardTickets.totalTickets) *
                                              100
                                            )
                                            : 0}
                                          %
                                        </h1>
                                        <p>Assigned</p>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              )}

                              {columns.name == "Final" && (
                                <>
                                  {checkPrivileges([12, 123, 124]) && (
                                    <div
                                      className="dashboardCardBody"
                                      style={{
                                        padding: 28,
                                        margin: "0 0 0 0",
                                        minHeight: "90px",
                                      }}
                                      onClick={
                                        isVisible == false
                                          ? () =>
                                            setShowJhaDashboardTable(
                                              !showJhaDashboardTable
                                            )
                                          : ""
                                      }
                                    >
                                      <div className="icon">
                                        <svg
                                          width="23"
                                          height="26"
                                          viewBox="0 0 23 26"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M2.5 25.4961C1.8125 25.4961 1.22375 25.2515 0.73375 24.7623C0.244583 24.2723 0 23.6836 0 22.9961V5.49609C0 4.80859 0.244583 4.21984 0.73375 3.72984C1.22375 3.24068 1.8125 2.99609 2.5 2.99609H7.75C8.02083 2.24609 8.47417 1.64193 9.11 1.18359C9.745 0.72526 10.4583 0.496094 11.25 0.496094C12.0417 0.496094 12.7554 0.72526 13.3913 1.18359C14.0263 1.64193 14.4792 2.24609 14.75 2.99609H20C20.6875 2.99609 21.2763 3.24068 21.7663 3.72984C22.2554 4.21984 22.5 4.80859 22.5 5.49609V22.9961C22.5 23.6836 22.2554 24.2723 21.7663 24.7623C21.2763 25.2515 20.6875 25.4961 20 25.4961H2.5ZM2.5 22.9961H20V5.49609H2.5V22.9961ZM5 20.4961H13.75V17.9961H5V20.4961ZM5 15.4961H17.5V12.9961H5V15.4961ZM5 10.4961H17.5V7.99609H5V10.4961ZM11.25 4.55859C11.5208 4.55859 11.745 4.46984 11.9225 4.29234C12.0992 4.11568 12.1875 3.89193 12.1875 3.62109C12.1875 3.35026 12.0992 3.12609 11.9225 2.94859C11.745 2.77193 11.5208 2.68359 11.25 2.68359C10.9792 2.68359 10.7554 2.77193 10.5788 2.94859C10.4013 3.12609 10.3125 3.35026 10.3125 3.62109C10.3125 3.89193 10.4013 4.11568 10.5788 4.29234C10.7554 4.46984 10.9792 4.55859 11.25 4.55859Z"
                                          
                                          />
                                        </svg>
                                      </div>

                                      <div className="textdash">
                                        Hazard Analysis Due
                                      </div>

                                      <div className="number2">
                                        {
                                          getJhaSetupDashboardData?.data
                                            ?.listCount
                                        }
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              {columns.items.map((store, index) => (
                                <Draggable
                                  draggableId={store?.cartId.toString()}
                                  key={store.cartId}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                      className="dashboardCardBody"
                                      style={{
                                        userSelect: "none",
                                        padding: 28,
                                        margin: "0 0 0 0",
                                        minHeight: "90px",

                                        ...provided.draggableProps.style,
                                      }}
                                      onClick={
                                        isVisible == false
                                          ? () => handleCardClick(store?.cartId)
                                          : ""
                                      }
                                    >
                                      <div className="icon">
                                      <svg
                                          width="23"
                                          height="26"
                                          viewBox="0 0 23 26"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M2.5 25.4961C1.8125 25.4961 1.22375 25.2515 0.73375 24.7623C0.244583 24.2723 0 23.6836 0 22.9961V5.49609C0 4.80859 0.244583 4.21984 0.73375 3.72984C1.22375 3.24068 1.8125 2.99609 2.5 2.99609H7.75C8.02083 2.24609 8.47417 1.64193 9.11 1.18359C9.745 0.72526 10.4583 0.496094 11.25 0.496094C12.0417 0.496094 12.7554 0.72526 13.3913 1.18359C14.0263 1.64193 14.4792 2.24609 14.75 2.99609H20C20.6875 2.99609 21.2763 3.24068 21.7663 3.72984C22.2554 4.21984 22.5 4.80859 22.5 5.49609V22.9961C22.5 23.6836 22.2554 24.2723 21.7663 24.7623C21.2763 25.2515 20.6875 25.4961 20 25.4961H2.5ZM2.5 22.9961H20V5.49609H2.5V22.9961ZM5 20.4961H13.75V17.9961H5V20.4961ZM5 15.4961H17.5V12.9961H5V15.4961ZM5 10.4961H17.5V7.99609H5V10.4961ZM11.25 4.55859C11.5208 4.55859 11.745 4.46984 11.9225 4.29234C12.0992 4.11568 12.1875 3.89193 12.1875 3.62109C12.1875 3.35026 12.0992 3.12609 11.9225 2.94859C11.745 2.77193 11.5208 2.68359 11.25 2.68359C10.9792 2.68359 10.7554 2.77193 10.5788 2.94859C10.4013 3.12609 10.3125 3.35026 10.3125 3.62109C10.3125 3.89193 10.4013 4.11568 10.5788 4.29234C10.7554 4.46984 10.9792 4.55859 11.25 4.55859Z"
                                          
                                          />
                                        </svg>
                                      </div>

                                      <div className="textdash">
                                        {store.cartName}
                                      </div>
                                      <div className="number2">
                                        {store.count}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    );
                  })}
                </DragDropContext>
              </>
            </div>
          </div>
          {showJhaDashboardTable && (
            <JHADashboardTable
              itemsCount={getJhaSetupDashboardData?.data?.listCount ?? 0}
              itemList={getJhaSetupDashboardData?.data?.jhaDashboardList ?? []}
              isLoading={getJhaSetupDashboardData?.isLoading}
            />
          )}
          <>
            {" "}
            <div className="headingcustomigeCreateNews">
              <h1 className="heading1">Trending News</h1>
              {/* {adminView && ( */}
              <div onClick={createNews} className="customizedash">
                <p>Create News/Announcement</p>
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.08334 14.0833H10.9167V8.58331H9.08334V14.0833ZM10 6.74998C10.2597 6.74998 10.4776 6.66198 10.6536 6.48598C10.829 6.31059 10.9167 6.09303 10.9167 5.83331C10.9167 5.57359 10.829 5.35573 10.6536 5.17973C10.4776 5.00434 10.2597 4.91665 10 4.91665C9.74029 4.91665 9.52273 5.00434 9.34734 5.17973C9.17134 5.35573 9.08334 5.57359 9.08334 5.83331C9.08334 6.09303 9.17134 6.31059 9.34734 6.48598C9.52273 6.66198 9.74029 6.74998 10 6.74998ZM10 18.6666C8.73195 18.6666 7.54029 18.4259 6.42501 17.9443C5.30973 17.4634 4.33959 16.8104 3.51459 15.9854C2.68959 15.1604 2.03662 14.1903 1.55568 13.075C1.07412 11.9597 0.833344 10.768 0.833344 9.49998C0.833344 8.23192 1.07412 7.04026 1.55568 5.92498C2.03662 4.8097 2.68959 3.83956 3.51459 3.01456C4.33959 2.18956 5.30973 1.53629 6.42501 1.05473C7.54029 0.573785 8.73195 0.333313 10 0.333313C11.2681 0.333313 12.4597 0.573785 13.575 1.05473C14.6903 1.53629 15.6604 2.18956 16.4854 3.01456C17.3104 3.83956 17.9634 4.8097 18.4443 5.92498C18.9259 7.04026 19.1667 8.23192 19.1667 9.49998C19.1667 10.768 18.9259 11.9597 18.4443 13.075C17.9634 14.1903 17.3104 15.1604 16.4854 15.9854C15.6604 16.8104 14.6903 17.4634 13.575 17.9443C12.4597 18.4259 11.2681 18.6666 10 18.6666Z"
                    fill={mode === "dark" ? `#fff` : "#666666"}
                  />
                </svg>
              </div>
              {/* )} */}
            </div>
            <Suspense fallback={<div>please wait.....</div>}>
              <NewsList NewsData={NewsData} />
            </Suspense>
            <Grid container item justifyContent="right" lg={12} mt={5}>
              <div className="seeall" onClick={listNews}>
                <p>See All</p>
              </div>
            </Grid>
          </>
          {/* )} */}

          <Grid item container lg={12}>
            <Grid lg={3} mt={6}>
              {isVisible && (
                <Button
                  varientContained={true}
                  disabled={isVisible ? false : true}
                  label={"save"}
                  className="cusmizebutton"
                  onClick={(e) => handlesubmit()}
                />
              )}
            </Grid>
          </Grid>
        </section>
      )}
    </>
  );
};

export default Dashboard;
