import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./observationContainer.scss";
import Loader from "../../../library/common/Loader";
import ObservationForm from "../component/observationForm";
import * as userAction from "../../../redux/actions/userAction";
import * as observationAction from "../../../redux/actions/observationAction";
import SuccessScreen from "../../../library/custom/success/successScreen";
import {
  ACCESS_DENIED,
  VIEW_OBSERVATION_LISTS,
} from "../../../constant/routeContant";
import { fileUpload } from "../../../services/fileUploadService";
import { validateFileSize } from "../../../utils/helper";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { useNavigate } from "react-router-dom";

const AddObservationContainer = () => {
  const disptch = useDispatch();
  const navigate = useNavigate();
  const searchOption = useSelector((state) => state.users.data);
  // const fileUploadData = useSelector((state) => state.fileUpload.data);
  const isAutoSearchDataLoading = useSelector((state) => state.users.isLoading);
  const assetOnUserList = useSelector((state) => state.getAssetOnUser.data);
  const supervisorOnAssetList = useSelector(
    (state) => state.getSupervisorOnAsset.data
  );

  const [successScreens, setIsSuccess] = useState(false);
  const addObservationData = useSelector((state) => state.addObservationReport);
  const addObservationDataStatus = useSelector(
    (state) => state.addObservationReport.status
  );

  const searchUsers = (searchType, searchText) => {
    disptch(userAction.searchUsers(searchType, searchText));
  };

  const showLoader = useSelector(
    (state) =>
      state?.fileUpload?.isLoading || state?.addObservationReport?.isLoading
  );

  function handleSubmit(data) {
    setIsSuccess(true);
    disptch(observationAction.observation(data));
  }

  const [fileError, setFileError] = useState(null);
  const [countError, setCountError] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [fileUploadData, setFileUploadData] = useState();

  const uploadFile = async (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileError(validation.errorMessage);
      setCountError(countError + 1);
      return;
    }
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadData(files);
      })
      .catch((error) => {
        setCountError(countError + 1);
        setFileError("Error while uploading files.");
      });
  };

  useEffect(() => {
    setIsSuccess(false);
  }, []);
  const successScreen = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={"Observation Added Successfully"}
        route={VIEW_OBSERVATION_LISTS}
        label1="Observtion ID"
        label4="Observtion in "
      ></SuccessScreen>
    );
  };
  useEffect(() => {
    searchUsers("", "");
  }, []);

  function getAssetOnUser(params) {
    const data = {
      assetType: 1,
      userId: params.userId,
    };
    disptch(userAction.getAssetOnUser(data));
  }
  function getSupervisorOnAsset(params) {
    const data = {
      assetType: 1,
      assetId: params,
    };
    disptch(userAction.getSupervisorOnAsset(data));
  }
  return (
    <>
      {checkPrivileges([12, 84, 85]) ? (
        <>
          {(showLoader || isLoader) && <Loader />}
          {addObservationDataStatus === "SUCCESS" &&
          addObservationData &&
          successScreens ? (
            successScreen(addObservationData?.data?.id)
          ) : (
            <div className="observation-form">
              <ObservationForm
                handleSubmit={handleSubmit}
                searchOption={searchOption}
                searchUsers={searchUsers}
                uploadFile={uploadFile}
                fileUploadData={fileUploadData}
                isUserDetails={true}
                isAutoSearchDataLoading={isAutoSearchDataLoading}
                getAssetOnUser={getAssetOnUser}
                assetOnUserList={assetOnUserList?.jobSite ?? []}
                getSupervisorOnAsset={getSupervisorOnAsset}
                supervisorOnAssetList={supervisorOnAssetList?.result ?? []}
                fileError={fileError}
                setFileUploadData={setFileUploadData}
                setFileError={setFileError}
              />
            </div>
          )}
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default AddObservationContainer;
