import React, { useEffect, useState } from "react";
import LessonList from "../component/lessonsList/lessonsList";
import * as lmsAction from "../../../redux/actions/lmsAction";
import { useDispatch, useSelector } from "react-redux";
import LmsHeader from "../component/lmsHeader/lmsHeader";
import {
  CREATE_LESSON,
  COURSES,
  LESSON,
  ACCESS_DENIED,
} from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import {
  PAGE_SIZE,
  ORG_ID,
  USER_ID,
  API_STATUS,
} from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const LessonsContainer = () => {
  const dispatch = useDispatch();
  const LessondeleteStatus = useSelector((state) => state.deleteLesson.status);
  const [isdisable, setisdisable] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const publishLessonStatus = useSelector(
    (state) => state.publishLesson.status
  );
  
  let navigate = useNavigate();

  const publishLessons = async (data) => {
    setisdisable(true);
    dispatch(lmsAction.publishLesson(data));
  };
  const lessionListData = useSelector((state) => state.getLessonsList);

  const dataHeader = {
    title: "Training Management",

    btnLink: CREATE_LESSON,
    menu: [
      {
        route: COURSES,
        text: "Courses",
      },
      {
        route: LESSON,
        text: "Lessons",
      },
    ],
  };
  if (checkPrivileges([12, 11])) {
    dataHeader.btnText = "Create Lesson";
  }
  const defaultData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    search: "",
    catIds: "",
    userId: USER_ID(),
    organisationId: ORG_ID(),
    status: "",
    isSendMail: false,
  };
  const defaultCategoryData = {
    pageNumber: 1,
    isGlobal: "",
  };

  useEffect(() => {
    const isAllowedDash = checkPrivileges([12, 11, 10]);
    if (isAllowedDash === false) {
      navigate(ACCESS_DENIED);
    }
    getLessonListData(defaultData);
    getategoryListData(defaultCategoryData);
  }, []);

  const getLessonListData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(lmsAction.getLessonsList(data));
  };
  const getategoryListData = (data) => {
    dispatch(lmsAction.getCategoryList(data));
  };
  const categoryListData = useSelector((state) => state.getCategoryList?.data);
  const deleteLesson = (data) => {
    dispatch(lmsAction.deleteLesson(data));
  };

  useEffect(() => {
    if (lessionListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Lessions has been sent to registered email ID");
    }
  }, [lessionListData]);

  return (
    <>
      <LmsHeader dataHeader={dataHeader} />
      <ToastContainer />
      <LessonList
        LessondeleteStatus={LessondeleteStatus}
        publishLessonStatus={publishLessonStatus}
        onSubmit={publishLessons}
        getLessonListData={getLessonListData}
        lessionListData={lessionListData}
        categoryListData={categoryListData}
        deleteLesson={deleteLesson}
        setisdisable={setisdisable}
        isdisable={isdisable}
      />
    </>
  );
};

export default LessonsContainer;
