import React, { useState, useEffect, useRef } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import InjuryRecordable from "../../incidents/add-incident/component/injuryRecordable";
import { InputField } from "../../../library/custom/textBox/InputField";
import { Grid } from "@mui/material";
import Witness from "../../../library/custom/witness/Witness";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import MultiSelectBox from "../../../library/custom/selectMenu/MultiSelectBox";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import CreateAction from "../../../library/custom/createAction/CreateAction";
import Button from "../../../library/custom/button/Button";
import {
  API_STATUS,
  INCIDENT_STATUS,
  ORG_ID,
} from "../../../constant/constant";
import intl from "react-intl-universal";
import useDebounce from "../../../utils/useDebounce";
import TextEditor from "../../../library/custom/textEditor/TextEditor";
import FormDropZone from "../../../library/dropZone/FormDropZoneIncident";

const PersonalInjuryForm = ({
  incident,
  caseData,
  onSubmit,
  searchOption,
  personalReportData,
  uploadFile,
  fileUploadData,
  caseId,
  incidentId,
  editMode,
  searchUsers,
  searchAllUsers,
  isLoading,
  allUsers,
  personalReportDataStatus,
  getAssetOnUser,
  assetOnUserList,
  getSupervisorOnAsset,
  supervisorOnAssetList,
  assetOnUserData,
  fileError,
  countError,
}) => {
  const [disableEmpName, setDisableEmpName] = useState(false);
  const [searchtext, setSeachText] = useState("");
  const debounceAs = useDebounce(searchtext, 500);
  useEffect(() => {
    if (debounceAs) {
      searchUsers(formValue.empType, debounceAs);
    }
  }, [debounceAs]);
  const [witnessList, setWitnessList] = useState([
    {
      id: Date.now() + Math.floor(Math.random() * 100),
      witnessName: "",
      employerName: "",
      jobTitle: "",
      statement: "",
      signature: "",
      files: "",
      isErrorWitnessName: false,
      isErrorStatement: false,
      errorWitnessNameMsg: "",
      errorStatementMsg: "",
    },
  ]);
  const [usess, setUsrss] = useState(searchOption?.users);
  useEffect(() => {
    if (personalReportData && incidentId) {
      const { incCase, witness, osha301, actions, personalInjury } =
        personalReportData;

      setDisableEmpName(true);
      setWitnessType(personalInjury?.isWitness);

      if (witness.length) {
        const witnessList1 = [];
        witness.forEach((items, index) => {
          witnessList1.push({
            employerName: items.employerName,
            witnessName: items.witnessName,
            jobTitle: items.jobTitle,
            statement: items.statement,
            files: items.files,
            id: items.witnessId,
          });
        });
        setWitnessList(witnessList1);
      }

      if (personalReportDataStatus.status === API_STATUS.SUCCESS) {
        let contractEmp =
          personalInjury?.employmentType === 1
            ? "incident.personalInjury.contractorsName"
            : "incident.personalInjury.employeeName";
        setEmplyeeNameText(intl.get(contractEmp));
        setJobSiteData([
          { key: personalInjury?.jobSiteId, value: personalInjury?.jobSite },
        ]);
        setSupervisorData([
          {
            key: personalInjury?.supervisorId,
            value: personalInjury?.supervisor,
          },
        ]);

        setEmployeeNameValue({
          userId: personalInjury?.employeeId,
          firstName: personalInjury?.employee,
        });
        setFormValue({
          empType: personalInjury?.employmentType,
          whereOccured: personalInjury?.incidentLocation,
          witnessType: "No",
          basicFirstAidData: personalInjury?.treatedType,
          descriptionOfInjury: personalInjury?.descriptionOfInjury,
          addDetails: personalInjury?.additionalDetails,
          type: true,
          userId: personalInjury?.employeeId,
          employeeName: personalInjury?.employee,
          jobSiteProjectName: personalInjury?.jobSiteId,
          jobTitle: personalInjury?.jobTitle,
          startDate: personalInjury?.startDate,
          companyEmployerName: personalInjury?.companyName,
          supervisor: personalInjury?.supervisorId,
          bodyPartInjured: personalInjury?.bodyPartInjuredList?.map(
            (item) => item.value
          ),
          contributingFactor: personalInjury?.contributingFactorsList?.map(
            (item) => item.value
          ),
          injuryTypess: personalInjury?.typeOfInjuryList?.map(
            (item) => item.value
          ),
          employmentType: personalInjury?.employmentType,
          basicFirstAid: personalInjury?.isTreatedByFirstAid ? "Yes" : "No",
          injuryRecordeds: personalInjury?.wasRecordableInOshaId,
          injuredOrMadePerson: osha301.objectOrSubstance,
          caseClassifys: osha301.classifyId,
          caseClassifyValuee: osha301.classifyValue,
          illnessTypes: osha301.typeOfIllness,
          howManyDays: osha301.classifyHowManyDays,
          osha301Id: osha301.osha301Id,
          filess: personalInjury?.files.map((file) => {
            return {
              fileUrl: file.value,
              id: file.key,
            };
          }),
          dateOfCase: incCase?.dateOfCase,
        });
        const imageUrl = personalInjury?.files
          .map((item) => item.value)
          .join("|");
        const imageId = personalInjury?.files.map((item) => item.key);
        setFileds(imageId);
        setAddPhoto(imageUrl);

        if (actions.length) {
          const actionList = [];
          actions.forEach((items, index) => {
            actionList.push({
              isFileAdded: items.isFileAdded,
              files: items.files,
              description: items.description,
              assignedPartyName: items.assignedPartyName,
              assignedPartyId: items.assignedPartyId,
              id: items.actionId,
              fileId: items?.files?.map((file) => file.fileId),
            });
          });
          setActionlist(actionList);
        }

        let associatedIncidentList = [...associatedIncidentTypes];
        setAssociatedIncidentTypes(associatedIncidentList);
      }
      getSupervisorOnAsset(personalInjury?.jobSiteId);
    }
  }, [personalReportDataStatus, personalReportData]);

  const userId = localStorage.getItem("userId");
  const autoComp = useRef(null);
  const {
    bodyPart,
    contributingFactors,
    injuryType,
    illnessType,
    associatedIncidentType,
    caseClassify,
    treatementType,
  } = incident;
  const todatDate = new Date();

  const intialState = {
    empType: "3", //Employee
    location: "",
    whereOccured: "",
    witnessType: "No",
    caseDescription: "",
    caseSummary: "",
    descriptionOfInjury: "",
    addDetails: "",
    employeeSignature: false,
    supervisorSignature: false,
    dateOfCase: new Date(),
    timeOfCase: todatDate.getHours() + ":" + todatDate.getMinutes(),
    type: false,
    employeeName: "",
    jobSiteProjectName: 0,
    jobTitle: "",
    startDate: "",
    companyEmployerName: "",
    supervisor: 0,
    bodyPartInjured: [],
    contributingFactor: [],
    injuryTypess: [],
    basicFirstAid: "No",
    injuryRecordeds: "1",
    basicFirstAidData: 0,
    isbasicFirstAidDataError: false,
    injuredOrMadePerson: "",
    caseClassifys: 0,
    caseClassifyValuee: "",
    osha301Id: 0,
    illnessTypes: 0,
    imageUrl: "",
    jobSiteProjectNameError: false,
    supervisorError: false,
    filess: [],
  };
  const [employeeNameValue, setEmployeeNameValue] = useState();
  const handleEmployeeName = (e, value) => {
    setSupervisorData(value?.supervisor);
    setEmployeeNameValue({
      userId: value?.userId ?? "",
      firstName: value?.firstName ?? "",
      lastName: value?.lastName ?? "",
    });
    setFormValue({
      ...formValue,
      employeeName: `${value?.firstName} ${value?.lastName}`,
      userId: value?.userId,
      companyId: value?.companyId,
      companyEmployerName: "",
      startDate: "",
      jobTitle: "",
    });
  };

  useEffect(() => {
    if (
      employeeNameValue !== undefined &&
      employeeNameValue !== null &&
      employeeNameValue !== 0 &&
      employeeNameValue !== "" &&
      employeeNameValue.userId !== ""
    ) {
      getAssetOnUser(employeeNameValue);
    }
  }, [employeeNameValue]);

  const [formValue, setFormValue] = useState(intialState);
  const [witnessType, setWitnessType] = useState(false);
  const [associatedIncidentTypes, setAssociatedIncidentTypes] = useState(
    associatedIncidentType
  );
  const [actionList, setActionlist] = useState([
    {
      assignedPartyName: "",
      file: [],
      isFileAdded: false,
      selectedFileName: "",
      description: "",
      id: Date.now() + Math.floor(Math.random() * 100),
      isCompleted: false,
      fileId: null,
      isError: false,
      isFileError: false,
    },
  ]);

  const [addPhoto, setAddPhoto] = useState("");
  const [jobSiteData, setJobSiteData] = useState([]);
  const [supervisorData, setSupervisorData] = useState([]);

  const bodypartInjuredOnChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormValue({
      ...formValue,
      bodyPartInjured: typeof value === "string" ? value.split(",") : value,
    });
  };

  const contributingFactorss = (event) => {
    const {
      target: { value },
    } = event;
    setFormValue({
      ...formValue,
      contributingFactor: typeof value === "string" ? value.split(",") : value,
    });
  };

  const onChangeInjuryTypes = (event) => {
    const {
      target: { value },
    } = event;
    setFormValue({
      ...formValue,
      injuryTypess: typeof value === "string" ? value.split(",") : value,
    });
  };
  const [fileIds, setFileds] = useState([]);

  const handleFileChange = (photos, id) => {
    setFileds(id);
    console.log(id);
    setAddPhoto(photos);
  };

  const handleChangeCreateActionDesc = (descText, data) => {
    let updatedActionList = actionList;
    updatedActionList.forEach((singleValue) => {
      if (singleValue.id === data.id) {
        singleValue[`description`] = descText;
      }
    });
    setActionlist(updatedActionList);
  };

  const handleOnclickAddAction = () => {
    if (actionList.length < 4) {
      const newActionList = [...actionList];
      newActionList.push({
        assignedPartyName: "",
        file: null,
        selectedFileName: "",
        description: "",
        id: Date.now() + Math.floor(Math.random() * 100),
        isCompleted: false,
        isError: false,
        isFileError: false,
      });
      setActionlist(newActionList);
    }
  };

  const radioButtonOnchangeWitnessHandler = (e) => {
    setWitnessType(JSON.parse(e.target.value));
  };

  const handleOnclickWitness = () => {
    if (witnessList.length < 4) {
      const newWitnessList = [...witnessList];
      newWitnessList.push({
        id: Date.now() + Math.floor(Math.random() * 100),
        witnessName: "",
        employerName: "",
        jobTitle: "",
        statement: "",
        signature: "",
        files: "",
        isErrorWitnessName: false,
        isErrorStatement: false,
        errorWitnessNameMsg: "",
        errorStatementMsg: "",
      });
      setWitnessList(newWitnessList);
    }
  };

  const handleOnChangeWitnessForm = (current, data, type) => {
    let elementId = "";
    let value = "";
    if (type === "uploadMedia" || type === "signature") {
      value = current;
      elementId = type;
    } else {
      value = current.target.value;
      elementId = current.target.id;
    }

    let updatedWitnessList = [...witnessList];
    updatedWitnessList.forEach((witnessLists) => {
      if (witnessLists.id === data.id) {
        if (elementId === "name") {
          witnessLists.witnessName = value;
        } else if (elementId === "jobTitle") {
          witnessLists.jobTitle = value;
        } else if (elementId === "statement") {
          witnessLists.statement = value;
        } else if (elementId === "employerName") {
          witnessLists.employerName = value;
        } else if (elementId === "uploadMedia") {
          witnessLists.files = value;
        }
      }
    });

    setWitnessList(updatedWitnessList);
  };

  const handleCreateActionCancelCta = (data) => {
    const newActionList = actionList.filter(
      (singleAction) => data.id !== singleAction.id
    );
    setActionlist(newActionList);
  };
  const removeActionImage = (singleAction) => {
    const actionListClone = [...actionList];
    actionListClone.forEach((Actionlist) => {
      if (Actionlist?.id === singleAction?.id) {
        Actionlist.fileId = null;
        Actionlist.file = [];
        Actionlist.isFileAdded = false;
        Actionlist.selectedFileName = "";
        Actionlist.isFileError = false;
      }
    });
    setIsError("");
    setActionlist(actionListClone);
  };

  const [selectedData, setSelectedData] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileUploaded, setSelectedFileUploaded] = useState([]);
  useEffect(() => {
    if (fileUploadData) {
      let updatedActionList = [...actionList];
      updatedActionList.forEach((actionLists) => {
        if (actionLists.id === selectedData.id) {
          actionLists.file = selectedFileUploaded;
          actionLists.isFileAdded = true;
          actionLists.fileId = [fileUploadData?.files[0].id];
          actionLists.selectedFileName = selectedFileName;
          actionLists.isFileError = false;
        }
      });
      setActionlist(updatedActionList);
    }
  }, [fileUploadData]);

  useEffect(() => {
    if (countError) {
      let updatedActionList = [...actionList];
      updatedActionList.forEach((actionLists) => {
        if (actionLists.id === selectedData.id) {
          actionLists.isFileError = true;
        }
      });
      setActionlist(updatedActionList);
    }
  }, [countError]);

  const handleUploadFileMethod = (fileUploaded, fileName, data) => {
    setSelectedData(data);
    setSelectedFileName(fileName);
    setSelectedFileUploaded(fileUploaded);
    uploadFile(fileUploaded);
  };

  const handleAddAssigneeCta = (event, users, data) => {
    let updatedActionList = actionList;
    updatedActionList.forEach((singleValue) => {
      if (singleValue.id === data.id) {
        singleValue[`assignedPartyName`] = users?.value || {};
        singleValue[`assignedPartyId`] = users?.key || 0;
        singleValue.isError = false;
        singleValue.isFileError = false;
      }
    });
    setActionlist(updatedActionList);
  };

  function findValueMultipleSelect(arrrayofObject, selectedArray) {
    let valueArray = [];
    arrrayofObject.forEach((data) => {
      if (selectedArray.indexOf(data.value) >= 0 && data.isActive) {
        valueArray.push(data.key);
      }
    });
    return valueArray;
  }

  const [isEmployeeName, setIsEmployeeName] = useState(false);
  const [isInjuryTypess, setIsInjuryTypess] = useState(false);
  const [isHowManyDays, setIsHowManyDays] = useState(false);
  const [emplyeeNameText, setEmplyeeNameText] = useState(
    intl.get("incident.personalInjury.employeeName")
  );
  const [employerNameText, setEmployerNameText] = useState(
    intl.get("incident.personalInjury.employersName")
  );

  const handleEmploymentType = (value) => {
    //Reseting Data
    const ele = autoComp.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
    setSupervisorData([]);
    setJobSiteData([]);
    if (value === "1") {
      setUsrss([]);
      setEmplyeeNameText(intl.get("incident.personalInjury.contractorsName"));
      setEmployerNameText(intl.get("incident.personalInjury.contractorsName"));
    } else {
      setUsrss([]);

      setEmplyeeNameText(intl.get("incident.personalInjury.employeeName"));
      setEmployerNameText(intl.get("incident.personalInjury.employersName"));
    }
    setFormValue({
      ...formValue,
      empType: value,
      employeeName: "",
      jobTitle: "",
      startDate: "",
      companyEmployerName: "",
    });
  };

  const cancelWitnessList = (id) => {
    const removeWitnessList = witnessList.filter(
      (witness) => witness.id !== id
    );
    setWitnessList(removeWitnessList);
  };

  const handleDeleteImageURL = (urls, index) => {
    fileIds.splice(index, 1);
    setFileds(fileIds);
    const previousImg = addPhoto.split("|");
    setAddPhoto(previousImg.filter((url) => url !== urls).join("|"));
  };

  const handleDeleteWitnessImage = (urls, singleWitness) => {
    const cloneWitness = [...witnessList];
    cloneWitness.forEach((singleWitn) => {
      if (singleWitn.id === singleWitness.id) {
        singleWitn.files = singleWitn.files
          .split("|")
          .filter((url) => url !== urls)
          .join("|");
      }
    });
    setWitnessList(cloneWitness);
  };
  const [isError, setIsError] = useState(false);
  const [isbasicFirstAidDataError, setIsbasicFirstAidDataError] =
    useState(false);
  //Data mapping to call API
  function mappedData(isSaveAsDraft) {
    let errors = false;
    if (!isSaveAsDraft) {
      let updatedwitnessList = [...witnessList];
      if (updatedwitnessList.length > 0 && witnessType) {
        updatedwitnessList?.forEach((data) => {
          if (data.witnessName.trim() === "") {
            data.witnessName = "";
            data.isErrorWitnessName = true;
            data.errorWitnessNameMsg = "Please enter this required field.";
            errors = true;
          }
          if (data.statement.trim() === "") {
            data.statement = "";
            data.isErrorStatement = true;
            data.errorStatementMsg = "Please enter this required field.";
            errors = true;
          }
        });
      }
      setWitnessList(updatedwitnessList);

      let cloneActionList = [...actionList];
      cloneActionList.forEach((items) => {
        if (items.description || items.isFileAdded) {
          if (!items.assignedPartyId) {
            items.isError = true;
            errors = true;
          } else {
            items.isError = false;
          }
        }
      });
      setActionlist(cloneActionList);
      if (
        formValue.caseClassifyValuee?.toLowerCase() === "death" ||
        formValue.caseClassifyValuee?.toLowerCase() === "other recordable" ||
        formValue.caseClassifysValue == ""
      ) {
      } else {
        if (parseInt(formValue.howManyDays) === 0) {
          setIsHowManyDays(true);
          errors = true;
        } else {
          setIsHowManyDays(false);
        }
      }

      if (!formValue.employeeName) {
        setIsEmployeeName(true);
        errors = true;
      }
      if (!employeeNameValue) {
        setIsEmployeeName(true);
        errors = true;
      }
      if (employeeNameValue?.userId === "") {
        setIsEmployeeName(true);
        errors = true;
      }
      if (formValue.injuryTypess.length === 0) {
        setIsInjuryTypess(true);
        errors = true;
      }
      if (
        formValue.basicFirstAid === "No" &&
        formValue.basicFirstAidData == 0
      ) {
        setIsbasicFirstAidDataError(true);
        errors = true;
      }

      if (!formValue.jobSiteProjectName && !formValue.supervisor) {
        setFormValue({
          ...formValue,
          jobSiteProjectNameError: true,
          supervisorError: true,
        });
      } else {
        if (!formValue.jobSiteProjectName) {
          setFormValue({ ...formValue, jobSiteProjectNameError: true });
          errors = true;
        }

        if (!formValue.supervisor) {
          setFormValue({ ...formValue, supervisorError: true });
          errors = true;
        }
      }

      if (errors) {
        window.scrollTo({
          top: 1050,
          left: 0,
          behavior: "smooth",
        });
        return false;
      }
    }
    setIsError(false);
    const associatedIncidentTypeData = [];
    associatedIncidentTypes.forEach((data) => {
      if (data.isSelected === true) {
        associatedIncidentTypeData.push(data.key);
      }
    });
    const contributingFactorsData = findValueMultipleSelect(
      contributingFactors,
      formValue.contributingFactor
    );
    const selectedBodyPart = findValueMultipleSelect(
      bodyPart,
      formValue.bodyPartInjured
    );
    const selectedInjurtType = findValueMultipleSelect(
      injuryType,
      formValue.injuryTypess
    );
    const actionListData = [];
    actionList.forEach((data) => {
      actionListData.push({
        assignedPartyId: data.assignedPartyId,
        isFileAdded: data.isFileAdded,
        files: data.fileId,
        description: data.description,
      });
    });

    const filterActionData = actionListData?.filter(
      (item) => item?.assignedPartyId !== undefined
    );

    const witnessListData = [];
    witnessList.forEach((data) => {
      witnessListData.push({
        witnessName: data.witnessName,
        employerName: data.employerName,
        jobTitle: data.jobTitle,
        statement: data.statement,
        files: `${data.files}`,
      });
    });

    const data = {
      caseId: caseId === 0 ? caseData.caseId : caseId,
      status: INCIDENT_STATUS.SUBMITTED,
      createdBy: parseInt(userId),
      incidentId: incidentId,
      orgId: ORG_ID,
      personalInjury: {
        employeeId: formValue.userId,
        companyId: formValue.companyId,
        supervisorId: formValue.supervisor,
        jobSiteId: formValue.jobSiteProjectName,
        employmentType: formValue.empType,
        descriptionOfInjury: formValue.descriptionOfInjury,
        isTreatedByFirstAid: formValue.basicFirstAid == "No" ? false : true,
        treatedType:
          formValue.basicFirstAid === "Yes" ? 0 : formValue.basicFirstAidData,
        recordableInOshaId: 3,
        additionalDetails: formValue.addDetails,
        wasRecordableInOshaId: formValue.injuryRecordeds,
        isWitness: witnessType,
        contributingFactors: contributingFactorsData,
        typeOfInjury: selectedInjurtType,
        bodyPartInjured: selectedBodyPart,
        incidentLocation: formValue.whereOccured,
        fileIds: fileIds,
      },
      osha301:
        formValue.injuryRecordeds == "0"
          ? {
              objectOrSubstance: formValue.injuredOrMadePerson,
              classifyId: formValue.caseClassifys,
              classifyHowManyDays:
                formValue.caseClassifyValuee?.toLowerCase() === "death" ||
                formValue.caseClassifyValuee?.toLowerCase() === "other recordable" ||
                !formValue.howManyDays
                  ? null
                  : formValue.howManyDays,
              classifyOtherRecordable: "",
              typeOfIllness: formValue.illnessTypes,
              osha301Id: formValue.osha301Id,
            }
          : {},
      witness: witnessType ? witnessListData : [],
      actions: filterActionData?.length > 0 ? filterActionData : [],
    };

    return { personalInjury: data };
  }

  const submitData = () => {
    const data = mappedData(false);
    if (!data) {
    } else {
      onSubmit(data);
    }
  };

  const saveData = () => {
    const data = mappedData(true);
    data.personalInjury.status = INCIDENT_STATUS.DRAFT;
    onSubmit(data);
  };

  const [witnessFilesData, setWitnessFilesData] = useState("");
  const [witnessData, setwitnessData] = useState("");

  const witnessFileUpload = (files, single) => {
    setWitnessFilesData(files);
    setwitnessData(single);
  };
  useEffect(() => {
    let updatedWitnessList = [...witnessList];
    updatedWitnessList.forEach((witnessLists) => {
      if (witnessLists.id === witnessData.id) {
        witnessLists.files = witnessFilesData;
      }
    });
    setWitnessList(updatedWitnessList);
  }, [witnessFilesData]);

  const searchUsersByProject = (type, text) => {
    searchAllUsers(type, text);
  };

  useEffect(() => {
    if (searchOption) {
      setUsrss(searchOption?.users);
      setFormValue({
        ...formValue,
        jobSiteProjectName: null,
        supervisor: null,
      });
      setDisableEmpName(false);
    }
  }, [searchOption]);
  useEffect(() => {
    setJobSiteData(assetOnUserList);
  }, [assetOnUserList]);
  useEffect(() => {
    setSupervisorData(supervisorOnAssetList);
  }, [supervisorOnAssetList]);
  useEffect(() => {
    setFormValue({
      ...formValue,
      companyEmployerName: assetOnUserData?.companyName ?? "",
      startDate: assetOnUserData?.startDate ?? "",
      jobTitle: assetOnUserData?.jobTitle ?? "",
    });
  }, [assetOnUserData]);

  return (
    <>
      <div className="overidePersonal">
        <Grid container mt={4}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormLabels label={intl.get("incident.personalInjury.empType")} />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              <RadioButton
                checked={formValue.empType == "3"}
                value="3"
                label="Employee"
                disabled={editMode ? true : false}
                onChange={(e) => handleEmploymentType(e.target.value)}
              ></RadioButton>
              <RadioButton
                checked={formValue.empType == "1"}
                value="1"
                disabled={editMode ? true : false}
                label="Contractor"
                onChange={(e) => handleEmploymentType(e.target.value)}
              ></RadioButton>
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid item container spacing={2} mt={3}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <>
                {formValue.empType == 1 ? (
                  <FormLabels label="Contractor's Name" isRequired={true} />
                ) : (
                  <FormLabels label="Employee's Name" isRequired={true} />
                )}

                <SearchAutoComplete
                  optionsData={isLoading ? [] : usess ?? []}
                  isUserDetails={true}
                  value={employeeNameValue}
                  isLoading={isLoading}
                  autoCompClear={autoComp}
                  typeOnchange={(event) => {
                    setSeachText(event.target.value);
                  }}
                  onChange={(event, value) => handleEmployeeName(event, value)}
                  isError={isEmployeeName}
                  errorMsg={
                    isEmployeeName &&
                    (!employeeNameValue || employeeNameValue.userId === "")
                      ? "Selection is required for this field ."
                      : ""
                  }
                />
              </>
              {/* )} */}
            </FormControl>
          </Grid>
          {formValue.empType == 3 && (
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  disabled={true}
                  type="text"
                  label={employerNameText}
                  value={formValue.companyEmployerName}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>

        <Grid item container mt={3} spacing={2}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels
                isRequired={true}
                label={intl.get("incident.personalInjury.jobsiteName")}
              />
              <SelectMenu
                placeholder={"Please Select"}
                disabled={disableEmpName}
                isError={formValue.jobSiteProjectNameError}
                listData={jobSiteData}
                value={formValue.jobSiteProjectName}
                onchangehandler={(e) => {
                  setFormValue({
                    ...formValue,
                    jobSiteProjectName: e.target.value,
                  });
                  getSupervisorOnAsset(e.target.value);
                }}
              />
            </FormControl>
          </Grid>

          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels
                isRequired={true}
                label={intl.get("incident.personalInjury.supervisor")}
              />
              <SelectMenu
                placeholder={"Please Select"}
                disabled={disableEmpName}
                listData={supervisorData}
                isError={formValue.supervisorError}
                value={formValue.supervisor}
                onchangehandler={(e) =>
                  setFormValue({ ...formValue, supervisor: e.target.value })
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        {formValue.empType == 3 && (
          <Grid item container mt={3} spacing={2}>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  disabled={true}
                  type="text"
                  label={intl.get("incident.personalInjury.startDate")}
                  value={formValue.startDate}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <InputField
                  fullWidth={true}
                  disabled={true}
                  type="text"
                  label={intl.get("incident.personalInjury.jobTitle")}
                  value={formValue.jobTitle}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Grid item container mt={3} spacing={2}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputField
                fullWidth={true}
                type="text"
                label={intl.get("incident.personalInjury.caseOccur")}
                value={formValue.whereOccured}
                onChange={(e) =>
                  setFormValue({ ...formValue, whereOccured: e.target.value })
                }
              />
            </FormControl>
          </Grid>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels
                label={intl.get("incident.personalInjury.contributingFactor")}
              />
              <MultiSelectBox
                placeholder={"Please Select"}
                listData={contributingFactors?.filter((list) => list.isActive)}
                onChange={contributingFactorss}
                value={formValue.contributingFactor || []}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container mt={3} spacing={2}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels
                label={intl.get("incident.personalInjury.typeOfInjury")}
                isRequired={true}
              />
              <MultiSelectBox
                placeholder={"Please Select"}
                listData={injuryType?.filter((list) => list.isActive)}
                onChange={onChangeInjuryTypes}
                value={formValue.injuryTypess || []}
                isError={isInjuryTypess}
                errorMessage="Selection is required for this field"
              />
            </FormControl>
          </Grid>

          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels
                label={intl.get("incident.personalInjury.bodyPartInjured")}
              />
              <MultiSelectBox
                placeholder={"Please Select"}
                listData={bodyPart?.filter((list) => list.isActive)}
                onChange={bodypartInjuredOnChange}
                value={formValue.bodyPartInjured || []}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={4} mb={3}>
          <Grid lg={8} xs={12}>
            <FormControl fullWidth>
              <FormLabels
                label={intl.get("incident.personalInjury.descOfInjury")}
              />
              <TextEditor
                placeholder={"Add Description of Injury Text Here..."}
                value={formValue.descriptionOfInjury}
                onChange={(value) =>
                  setFormValue({
                    ...formValue,
                    descriptionOfInjury: value,
                  })
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels
                label={intl.get("incident.personalInjury.injuryTreated")}
                isRequired={true}
              />
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radioGroup"
              >
                <RadioButton
                  checked={formValue.basicFirstAid === "Yes"}
                  value="Yes"
                  label="Yes"
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      basicFirstAid: e.target.value,
                    })
                  }
                ></RadioButton>
                <RadioButton
                  checked={formValue.basicFirstAid === "No"}
                  value="No"
                  label="No"
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      basicFirstAid: e.target.value,
                    })
                  }
                ></RadioButton>
              </RadioGroup>
            </FormControl>
          </Grid>
          {formValue.basicFirstAid === "No" && (
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <FormLabels label="Treatment Type" isRequired={true} />
                <SelectMenu
                  placeholder={"Please Select"}
                  listData={treatementType?.filter((list) => list.isActive)}
                  value={formValue.basicFirstAidData}
                  errorMsg="Selection is required for this field "
                  onchangehandler={(e) =>
                    setFormValue({
                      ...formValue,
                      basicFirstAidData: e.target.value,
                    })
                  }
                  isError={
                    formValue.basicFirstAidData == 0 && isbasicFirstAidDataError
                      ? true
                      : false
                  }
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
        {formValue.empType == 3 && (
          <Grid container mt={2} lg={7} xs={12} sm={12} md={4}>
            <Grid lg={8} xs={12} className="ContactMsgBox">
              <FormControl>
                <FormLabels
                  label={intl.get("incident.personalInjury.injuryRecordable")}
                />
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="radioGroup"
                >
                  <RadioButton
                    checked={formValue.injuryRecordeds == "0"}
                    value="0"
                    label="Yes"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        injuryRecordeds: e.target.value,
                      })
                    }
                  ></RadioButton>
                  <RadioButton
                    checked={formValue.injuryRecordeds == "1"}
                    value="1"
                    label="No"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        injuryRecordeds: e.target.value,
                      })
                    }
                  ></RadioButton>
                  <RadioButton
                    checked={formValue.injuryRecordeds == "2"}
                    value="2"
                    label="I'm not sure"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        injuryRecordeds: e.target.value,
                      })
                    }
                  ></RadioButton>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid className="conatctMsgRigt" lg={4} xs={12}>
              {formValue.injuryRecordeds === "2" ? (
                <div className="contactMsg">Contact Admin</div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        )}

        {formValue.injuryRecordeds == "0" && formValue.empType == 3 && (
          <InjuryRecordable
            location={formValue.location}
            caseId={caseId}
            name={formValue.employeeName}
            dateOfInjury={formValue.dateOfCase}
            caseClassify={caseClassify}
            illnessType={illnessType}
            howManyDays={formValue.howManyDays}
            whereOccured={formValue.whereOccured}
            isHowManyDays={isHowManyDays}
            setHowManyDays={(e) =>
              setFormValue({
                ...formValue,
                howManyDays: e >= 0 && e,
              })
            }
            caseClassifysValue={formValue.caseClassifys}
            caseClassifyValuee={formValue.caseClassifyValuee}
            setcaseClassifysValue={(e, value) =>
              setFormValue({
                ...formValue,
                caseClassifys: e?.target?.value,
                caseClassifyValuee: value?.props?.children,
              })
            }
            illnessTypesValue={formValue.illnessTypes}
            setillnessTypesValue={(e) =>
              setFormValue({ ...formValue, illnessTypes: e.target.value })
            }
            injuredOrMadePersonValue={formValue.injuredOrMadePerson}
            bodyPartValue={formValue.bodyPartInjured}
            jobTitle={formValue.jobTitle}
            setInjuredOrMadePersonValue={(e) =>
              setFormValue({
                ...formValue,
                injuredOrMadePerson: e.target.value,
              })
            }
          />
        )}

        <Grid container>
          <Grid lg={8} xs={12} sm={12} md={8} mt={2}>
            <FormDropZone
              label={intl.get("incident.personalInjury.addPhotos")}
              paraText1={"Drop the files here ..."}
              paraText2={"Drop Files here, "}
              paraText3={"or Browse"}
              handleFileChange={handleFileChange}
              isDoc={true}
              isImage={false}
              allFiles={true}
              isDynamics={true}
              fileType={10}
              imageUrl={addPhoto}
              filess={formValue.filess}
              deleteImageURL={(url, index) => handleDeleteImageURL(url, index)}
              id="addPhoto"
              key="addPhoto"
            />
          </Grid>
        </Grid>

        <Grid className="inputGrid" container mb={2}>
          <Grid lg={4} xs={12} sm={12} md={4}>
            <FormControl>
              <FormLabels label={intl.get("incident.personalInjury.witness")} />
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radioGroup"
              >
                <RadioButton
                  checked={witnessType === true}
                  value={true}
                  label="Yes"
                  onChange={radioButtonOnchangeWitnessHandler}
                  name="witness"
                ></RadioButton>
                <RadioButton
                  checked={witnessType === false}
                  value={false}
                  label="No"
                  onChange={radioButtonOnchangeWitnessHandler}
                  name="witness"
                ></RadioButton>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {witnessType &&
          witnessList.map((singleWitness, index) => (
            <Witness
              key={index}
              keyNo={index + 1}
              singleWitness={singleWitness}
              handleOnChangeWitnessForm={handleOnChangeWitnessForm}
              optionsData={searchOption?.users}
              isErrorWitnessName={singleWitness.isErrorWitnessName}
              isErrorStatement={singleWitness.isErrorStatement}
              errorWitnessNameMsg={singleWitness.errorWitnessNameMsg}
              errorStatementMsg={singleWitness.errorStatementMsg}
              id={singleWitness.id}
              cancelWitnessList={(id) => cancelWitnessList(id)}
              deleteImageURL={(url) =>
                handleDeleteWitnessImage(url, singleWitness)
              }
              witnessFileUpload={(photo) =>
                witnessFileUpload(photo, singleWitness)
              }
            />
          ))}
        {witnessType && (
          <div className="addBox">
            <Button
              varientTextGreen={true}
              disabled={witnessList.length === 4 ? true : false}
              label={intl.get("incident.personalInjury.addWitness")}
              onClick={handleOnclickWitness}
            />
          </div>
        )}

        {actionList.map((singleAction, index) => (
          <CreateAction
            key={index}
            singleAction={singleAction}
            handleChangeActionDescription={handleChangeCreateActionDesc}
            handleAddAssigneeCta={handleAddAssigneeCta}
            handleUploadFile={handleUploadFileMethod}
            handleCancelCta={handleCreateActionCancelCta}
            optionsData={allUsers?.users ?? []}
            searchUsers={searchUsersByProject}
            incidentId={personalReportData?.incidentId}
            isLoading={isLoading}
            isError={singleAction.isError}
            removeActionImage={removeActionImage}
            fileError={fileError}
          />
        ))}

        <div className="addBox">
          <Button
            varientTextGreen={true}
            disabled={actionList.length === 4 ? true : false}
            label={intl.get("incident.case.addAction")}
            onClick={handleOnclickAddAction}
          />
        </div>
        <Grid container mt={3}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <FormControl fullWidth>
              <FormLabels
                label={intl.get("incident.case.addAdditinalDetails")}
              />
              <TextEditor
                value={formValue.addDetails}
                placeholder={"Add Additional Details Text Here..."}
                onChange={(value) =>
                  setFormValue({ ...formValue, addDetails: value })
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={3} spacing={10} alignItems="center">
          <Grid lg={4} xs={12} sm={6} md={6} textAlign="center">
            <Button
              varientText={true}
              label={intl.get("incident.case.saveAsDraft")}
              onClick={saveData}
            />
          </Grid>
          <Grid lg={4} xs={12} sm={6} md={6} textAlign="right">
            {isError ? (
              <p className="errorMsg">Some field are required</p>
            ) : null}
            <Button
              varientContained={true}
              label={intl.get("incident.case.submit")}
              onClick={submitData}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PersonalInjuryForm;
