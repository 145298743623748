import React, { useEffect } from "react";
import SwRainfallData from "../components/SwRainfallData";
import * as swprojectAction from "../../../../redux/storm-water/actions/projectAction";
import { useSelector, useDispatch } from "react-redux";
import {
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
} from "../../../../constant/constant";
import * as clientAction from "../../../../redux/actions/clientAction";

const SwRainfallReportContainer = () => {
  const dispatch = useDispatch();
  const areAllEqual =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) &&
    parseInt(LOGIN_ORG_ID()) === parseInt(HBNEXT_ORG_ID);
  const allUsers = useSelector((state) => state.allUsers);
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };
  const allClients = useSelector((state) => state.searchAllClientsReducerData);
  const isSearchClientLoading = allClients.isLoading;

  const swRainFallData = useSelector((state) => state?.swrainFallData);
  const isLoading = swRainFallData.isLoading;
  const swGetRainFallDataList = (data) => {
    dispatch(swprojectAction.swGetRainfallDataList(data));
  };

  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    rainfallType: 1,
    projectId: 0,
    fromDate: "",
    toDate: "",
    search: "",
    OrgId: areAllEqual ? 1 : parseInt(ORG_ID()),
  };
  useEffect(() => {
    swGetRainFallDataList(defaultData);
  }, []);

  return (
    <div>
      <SwRainfallData
        allUsers={allUsers}
        searchClient={searchClient}
        isSearchClientLoading={isSearchClientLoading}
        allClients={allClients}
        swRainFallData={swRainFallData}
        swGetRainFallDataList={swGetRainFallDataList}
        isLoading={isLoading}
      />
    </div>
  );
};

export default SwRainfallReportContainer;
