import React, { useEffect, useState } from "react";
import SetupInspectionList from "../components/setupInspectionList";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ACCESS_DENIED,
  INSPECTION_AUTHORITY,
  SETUP_INSPECTION,
} from "../../../../constant/routeContant";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import { useDispatch, useSelector } from "react-redux";
import { ORG_ID, PAGE_SIZE, USER_ID } from "../../../../constant/constant";
import { ToastContainer, toast } from "react-toastify";
import * as clientAction from "../../../../redux/actions/clientAction";
import Loader from "../../../../library/common/Loader";
import * as assetsAction from "../../../../redux/actions/assetsAction";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const SetupInspectionListContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setupInspection = () => {
    navigate(SETUP_INSPECTION, {
      state: {
        addSetup: true,
      },
    });
  };
  const defaultData = {
    search: "",
    userId: 0,
    customerId: 0,
    projectId: 0,
    page: 1,
    pageSize: PAGE_SIZE,
    status: "",
    orgId: parseInt(ORG_ID()),
  };

  const getSwQuestionSetProjectMapList = useSelector(
    (state) => state.getSwQuestionSetProjectMapList
  );
  const getSwQuestionSetProjectMapListData = (data) => {
    dispatch(swInspectionAction.getSwQuestionSetProjectMapList(data));
  };
  const updateStatusQuestionSetProjectMap = useSelector(
    (state) => state.updateStatusQuestionSetProjectMap
  );
  const [clearUpdateQuestionSetStatus, setClearUpdateQuestionSetStatus] =
    useState(false);
  const updateSwQuestionSetProjectMapData = (data) => {
    setClearUpdateQuestionSetStatus(true);
    dispatch(swInspectionAction.updateStatusQuestionSetProjectMap(data));
  };
  const isLoading = useSelector(
    (state) => state.getSwQuestionSetProjectMapList?.isLoading
  );
  useEffect(() => {
    if (!location?.state) {
      getSwQuestionSetProjectMapListData(defaultData);
    }
  }, [location?.state]);

  const handleEditOnClick = (row, customerId) => {
    navigate(SETUP_INSPECTION, {
      state: {
        row: row?.mapId,
        editMode: true,
        projectNumber: row?.projectNo,
        projectName: row?.projectName,
        projectId: row?.projectId,
        customerId: customerId,
        customerName: row.customerName,
      },
    });
  };

  const handleManageOnClick = (row) => {
    navigate(INSPECTION_AUTHORITY, {
      state: {
        row: row?.mapId,
        manage: true,
      },
    });
  };

  useEffect(() => {
    if (
      updateStatusQuestionSetProjectMap?.status === "SUCCESS" &&
      updateStatusQuestionSetProjectMap?.isLoading === false &&
      clearUpdateQuestionSetStatus
    ) {
      toast("Question Set Project Map Status Updated successfully");
    }
  }, [updateStatusQuestionSetProjectMap]);

  function handleAllProjectSearch(event, value, customerId) {
    dispatch(
      assetsAction.getAssetDynomoList({
        searchText: event.target.value,
        type: 5,
        orgId: customerId,
      })
    );
  }
  const allProject = useSelector((state) => state.getAssetDynomoList);
  const isProjectLoading = allProject?.isLoading;

  const allClients = useSelector((state) => state.searchAllClientsReducerData);
  const isSearchClientLoading = allClients.isLoading;
  const searchClient = (event) => {
    dispatch(clientAction.searchAllClients(event.target.value));
  };
  const showLoader = useSelector(
    (state) => state.updateStatusQuestionSetProjectMap?.isLoading
  );
  return (
    <>
      {checkPrivileges([12, 99, 100, 101]) ? (
        <>
          {showLoader && <Loader />}

          <SetupInspectionList
            isLoading={isLoading}
            swQuestionSetProjectMapList={
              isLoading
                ? []
                : getSwQuestionSetProjectMapList?.data?.qsProjectMapList
            }
            setupInspection={setupInspection}
            count={getSwQuestionSetProjectMapList?.data?.count}
            getSwQuestionSetProjectMapListData={
              getSwQuestionSetProjectMapListData
            }
            handleEditOnClick={handleEditOnClick}
            updateSwQuestionSetProjectMapData={
              updateSwQuestionSetProjectMapData
            }
            handleAllProjectSearch={handleAllProjectSearch}
            isProjectLoading={isProjectLoading}
            allProjects={allProject?.data}
            allClients={allClients?.data}
            isSearchClientLoading={isSearchClientLoading}
            searchClient={searchClient}
            handleManageOnClick={handleManageOnClick}
            updateStatusQuestionSetProjectMap={
              updateStatusQuestionSetProjectMap
            }
            projectData={location?.state}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default SetupInspectionListContainer;
