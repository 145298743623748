import { getApiUrl } from "../config";
import { getRandomNumber } from "../utils/helper";
import { post } from "./axiosRequest";
import axios from "axios";

export const createFileUpload = async ({ data, fileType = 1 }) => {
  try {
    const url = `${
      getApiUrl().fileUploadURL
    }/api/file/uploadfiles?filetype=${fileType}`;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return await post(url, data, {
      headers: headers,
    });
  } catch (err) {
    return err;
  }
};

export const fileUpload = async (file) => {
  try {
    const fileName = getRandomNumber() + "." + file?.name?.split(".").pop();
    const type = file?.type;
    const preSignedS3Url = `${getApiUrl().adminApiUrl}/File/GetPreSignedS3Url`;
    const reqBody = {
      objects: [
        {
          objectKey: fileName,
          contentType: type,
        },
      ],
    };
    const s3Response = await post(preSignedS3Url, reqBody, {
      headers: { Authorization: localStorage.getItem("id_token") },
    });
    const { data: s3Data, status } = s3Response;
    if (!s3Data && status !== 200) {
      throw new Error("URL not dound");
    }
    const { preSignedUrls } = s3Data;
    
    // Set up options for the PUT request
    const options = {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": type,
        "x-amz-acl": "public-read",
      },
    };
    const url = preSignedUrls[0]?.preSignedUrl;
    if (file) {
      const res = await fetch(url, options);
      if (res) {
        return preSignedUrls;
      }
    }
  } catch (err) {
    return err;
  }
};

// export const fileUpload = async (formDataState) => {
//   try {
//     const filesData = [];
//     formDataState?.forEach(async (file) => {
//       const fileName = getRandomNumber() + "." + file?.name?.split(".").pop();
//       const type = file?.type;

//       const preSignedS3Url = `${
//         getApiUrl().adminApiUrl
//       }/File/GetPreSignedS3Url`;
//       const reqBody = {
//         objects: [
//           {
//             objectKey: fileName,
//             contentType: type,
//           },
//         ],
//       };
//       const s3Response = await post(preSignedS3Url, reqBody, {
//         headers: { Authorization: localStorage.getItem("id_token") },
//       });
//       const { data: s3Data, status } = s3Response;
//       if (!s3Data && status !== 200) {
//         throw new Error("URL not dound");
//       }
//       const { preSignedUrls } = s3Data;
//       // Set up options for the PUT request
//       const options = {
//         method: "PUT",
//         body: file,
//         headers: {
//           "Content-Type": type,
//           "x-amz-acl": "public-read",
//         },
//       };
//       const url = preSignedUrls[0]?.preSignedUrl;
//       if (file) {
//         const res = await fetch(url, options);
//         if (res) {
//           return preSignedUrls;
//         }
//       }
//     });
//     // if (filesData?.length === formDataState?.length) {
//     //   return filesData;
//     // }
//   } catch (err) {
//     return err;
//   }
// };

// export const fileUpload = async ({ data, fileName, type }) => {
//   try {
//     const preSignedS3Url = `${getApiUrl().adminApiUrl}/File/GetPreSignedS3Url`;
//     const reqBody = {
//       objects: [
//         {
//           objectKey: fileName,
//           contentType: type,
//         },
//       ],
//     };
//     const s3Response = await post(preSignedS3Url, reqBody, {
//       headers: { Authorization: localStorage.getItem("id_token") },
//     });
//     const { data: s3Data, status } = s3Response;
//     if (!s3Data && status !== 200) {
//       throw new Error("URL not dound");
//     }
//     const { preSignedUrls } = s3Data;

//     // Set up options for the PUT request
//     const options = {
//       method: "PUT",
//       body: data,
//       headers: {
//         "Content-Type": type,
//         "x-amz-acl": "public-read",
//       },
//     };
//     const url = preSignedUrls[0]?.preSignedUrl;
//     if (data) {
//       const res = await fetch(url, options);
//       if (res) {
//         return preSignedUrls;
//       }
//     }
//     return [];
//   } catch (err) {
//     return err;
//   }
// };
