import { useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import {
  Dashboard,
  LoginPage,
  LoginViaOtp,
  Onboarding,
  ResetPassword,
  ChangePassword,
  SignupPage,
  VerifyMobile,
  SwDashboard,
  Landingpage,
} from "../feature";
import PersonalInjuryContainer from "../feature/case/containers/personalInjuryContainer";
import AddVehicleContainer from "../feature/case/containers/addVehicleContainer";
import UtilityDamageContainer from "../feature/case/containers/utilityDamageContainer";
import StolenPropertyContainer from "../feature/case/containers/stolenPropertyContainer";
import NearMissesContainer from "../feature/case/containers/nearMissesContainer";
import CaseIncidentListContainer from "../feature/incidents/incidentList/incidentListIdContainer";
import IncidentReportListContainer from "../feature/incidents/incidentReportList/incidentReportListContainer";
import AddReprimandContainer from "../feature/inspections/container/addReprimandContainer";
import ReprimandListContiner from "../feature/inspections/container/reprimandListContiner";
import AddInspectionContainer from "../feature/inspections/";
import BasicInspection from "../feature/inspections/basic/";
import InspectionViewReport from "../feature/inspections/view/";

import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import AddObservationContainer from "../feature/observation/container/addObservationContainer";
import Faq from "../feature/support/container/faqContainer";
import SupportContainer from "../feature/support/container/supportContainer";
import TicketContainer from "../feature/support/container/ticketContainer";
import Connect from "../feature/support/container/connect";

import {
  PERSONAL_INJURY,
  VEHICLE_ACCIDENT,
  UTILITY_DAMAGE,
  STOLEN_PROPERTY,
  NEAR_MISS,
  INCIDENT_LIST,
  REPRIMAND_LIST,
  REPRIMAND,
  OBSERVATION,
  VIEW_OBSERVATION_LISTS,
  VIEW_OBSERVATION_DETAILS,
  OPEN_ITEMS_LIST,
  VIEW_OPEN_ITEMS_DETAILS,
  CREATE_LESSON,
  CREATE_COURSE,
  LESSON,
  COURSES,
  INSPECTION_REPORT,
  DASHBOARD,
  SWDASHBOARD,
  ONBOARDING,
  CASE_LIST,
  ADMIN_GROUP,
  ADMIN_USER,
  ADMIN_SAFETY_LOG,
  ADMIN_PROFILE,
  ADMIN_RESOURCE,
  ADMIN_CONTACTS,
  ADMIN_ADD_CONTACTS,
  ADMIN_VIEW_CONTACT,
  ADMIN_ADD_RESOURCE,
  USERCOURSES,
  USERLESSON,
  ADD_ADMIN_USER,
  LESSONDETAIL,
  COURSESDETAIL,
  ADD_ADMIN_GROUP,
  CREATE_QUIZ,
  ADMIN_VEHICLES,
  ADMIN_EQUIPMENTS,
  ADMIN_ASSETS,
  ADMIN_ADD_VEHICLES,
  ADMIN_ADD_EQUIPMENTS,
  ADMIN_EDIT_EQUIPMENT,
  ADMIN_ADD_ASSETS,
  USER_QUIZ,
  ADMIN_DIVISION,
  ADMIN_ADD_DIVISION,
  ADMIN_VIEW_DIVISION,
  ADMIN_PROJECT,
  ADMIN_ADD_PROJECT,
  ADMIN_ASSIGN_ROLE,
  ADMIN_ADD_NEW_ROLE,
  ADMIN_EDIT_DIVISION,
  USER_ASSIGNED,
  ADMIN_EDIT_NEW_ROLE,
  USER_ASSIGNED_LESSON,
  ADMIN_EDIT_CONTACT,
  ADMIN_EDIT_RESOURCE,
  CREDENTIAL_LOOKUP,
  MY_CREDENTIALS,
  MY_USER_ADD_CREDENTIAL,
  SETUP_CREDENTIAL,
  ADMIN_CREDENTIAL,
  ASSIGN_CREDENTIAL,
  SETUP_ADD_CREDENTIAL,
  VIEW_CREDENTIAL,
  ADMIN_CONTRACTOR_LIST,
  ADMIN_CONTRACTOR,
  VIEW_KUDOS,
  VIEW_VIOLATION,
  INSPECTION_ADMIN,
  ADMIN_PERSONAL_CONTRACTOR_LIST,
  ADMIN_PERSONAL_CONTRACTOR,
  ADMIN_CATEGORY_CREDENTIALS,
  ADMIN_CATEGORY_INCIDENT,
  ADMIN_UTILITY,
  VIEW_REPRIMAND_CONTAINER,
  ADMIN_CONTRACTOR_DETAILS,
  VIEW_REPRIMAND,
  INSPECTION_CATEGORY,
  INSPECTION_SUB_CATEGORY,
  INSPECTION_QUESTIONS,
  ADMIN_CLIENT_LIST,
  ADMIN_ADD_CLIENT,
  ADMIN_VIEW_CLIENT,
  ADMIN_CATEGORY_LMS,
  ADMIN_CATEGORY_SUPPORT,
  INSPECTION_SET,
  ADMIN_CATEGORY_ASSETS,
  ADMIN_CATEGORY_CREDENTIALSS,
  ADMIN_COMPANY_MANAGEMENT,
  ADMIN_USER_MANAGEMENT,
  ADMIN_ASSET_MANAGEMENT,
  ADMIN_CREDENTIAL_MANAGEMENT,
  REPORTS_ANALYTICS,
  ADMIN_ADD_CREDENTIAL,
  VIEW_ADMIN_CREDENTIAL,
  ADMIN_USER_CONFIGURATION,
  CASES,
  CASEINCIDENTS,
  ADDCASE,
  EDITCASE,
  USER_CONFIGURATION_VIEW,
  USER_ONBOARD_EDIT,
  REPORTS_CREDENTIAL_COUNT,
  ADMIN_CATEGORY_RESOURSE,
  TICKET_DASHBOARD,
  RESOLUTION_TEAM_TICKET,
  TICKET,
  SUPPORT,
  ADMIN_TICKET,
  ADMIN_FEEDBACK_TICKET,
  ADMIN_TICKET_DETAILS,
  CREATE_TICKET,
  USER_TICKET_DETAILS,
  ADMIN_FAQ,
  FAQ,
  CREATE_FAQ,
  FAQ_TOPIC,
  FAQ_DETAIL,
  INSPECTIONS,
  REPORTS_CREDENTIAL_LIST,
  TOOL_BOX_CREATE,
  TOOL_BOX_LIST,
  REPORTS_CREDENTIAL_VIEW,
  REPORTS_SAFETY_VIEW,
  REPORTS_DATA,
  REPORTS_TRAINING_SESSIONS,
  REPORTS_UTILITIES,
  TOOL_BOX_DETAIL,
  TOOL_BOX_ASSIGN,
  TOOL_BOX_VERIFY_ATTENDEES,
  TOOL_BOX_ASSIGN_USER,
  ADMIN_CONTRACTOR_MANAGEMENT,
  ADMIN_CONTRACTOR_CONFIGURATION_LIST,
  TOOL_BOX_USER,
  ADMIN_CONTRACTOR_CONFIGURATION_VIEW,
  ADMIN_CONTRACTOR_CONFIGURATION_EDIT,
  ADMIN_NONPRIVILEGE_USER_LIST,
  ADD_ADMIN_NONPRIVILEGE_USER,
  REPORTS_SAFETYLOG_LIST,
  TOOL_BOX_USERCOUNT_ASSIGN,
  REPORTS_PERSONAL_INJURY,
  ADMIN_ADD_IMAGE,
  VIEW_LOOKUP_CREDENTIAL,
  INSPECTION_TYPE,
  CONFIGURE_EDIT_INSPECTION,
  SETUP_INSPECTION,
  LINK_QUESTION_INSPECTION,
  ADD_INSPECTION_FORM,
  SW_CATEGORIES,
  SW_SUB_CATEGORIES,
  ADMIN_SITE_PROJECTS_MANAGEMENT,
  ADMIN_SITE_PROJECTS_LIST,
  ADMIN_ADD_SITE,
  ADMIN_ADD_STORM_WATERPROJECT,
  ADMIN_PROJECTS_LIST,
  INSPECTION_FORM_LIST,
  ITEM_FOUND_RESPONSES,
  SETUP_INSPECTION_LIST,
  SW_ADD_CONTACT,
  SW_CONTACT_LIST,
  ADMIN_VIEW_SITE,
  SW_CONTACT_PROJECT,
  SW_INSPECTION_CONFIGURATION,
  SW_ADD_PERMIT,
  SW_EDIT_PERMIT,
  SW_PERMIT_LIST,
  INSPECTION_GENERATOR,
  SW_CONFIGURE_PERMITEE_TYPE,
  SW_ADMIN_SAMPLINGLOG,
  SW_ADMIN_SAMPLINGLOG_HISTORY,
  SW_INSPECTION_ADD,
  SW_INSPECTION_LIST,
  SW_INSPECTION_VIEW,
  INSPECTION_AUTHORITY,
  SW_GENERAL_PERMIT,
  ADMIN_JHA_CONFIGURATION,
  ADMIN_JHA_CATEGORY,
  ADMIN_JHA_HAZARDS,
  ADMIN_JHA_ACTIVITIES,
  ADMIN_JHA_SET_CONTROLS,
  ADMIN_JHA_EDIT_CONTROLS,
  CONDUCT_JHA,
  CONDUCT_JHA_LIST,
  ADMIN_JHA_ADD_TEMPLATE,
  ADMIN_JHA_LIST_TEMPLATE,
  SW_OPEN_ITEMS,
  LANDING_PAGE,
  CONDUCT_JHA_REPORT,
  ADMIN_JHA_LIST_SERVICE,
  ADMIN_JHA_SETUP,
  SW_RAIN_FALL_DATA,
  SW_VIEW_PERMIT,
  INSPECTIONS_ACKNOWLEDGMENT,
  ADMIN_ADD_NEWS,
  ADMIN_LIST_NEWS,
  NEWSDETAIL,
  SW_INSPECTION_PROOF,
  SW_INSPECTION_PROOF_VIEW,
  SW_DAILY_REPORT,
  SW_INSPECTION_DAILY_VIEW,
  SW_REPORTS_ANALYTICS,
  STROM_WATER_ANALYTICS_REPORT_INSPECTION,
  ACCESS_DENIED,
  SW_SUMMARY_REPORT,
  SW_MAP_VIEW_REPORT,
  ADMIN_USER_INFO,
  ADMIN_ADD_FILES,
  ADMIN_CONTRACTOR_MAPPING,
  ADMIN_USER_SEARCH,
  ADMIN_USER_SEARCH_BY_ROLE,
  ADMIN_USER_VIEW_BY_ROLE,
  LMS_CLIENT_LESSON_MAPPING,
  PAGE_NOT_FOUND,
  ADMIN_PROCORE_USER_LIST,
  ADMIN_PROCORE_PROJECT_LIST,
  ADMIN_VIEW_PROCORE_PROJECT,
  ADMIN_VIEW_USER,
  SW_RAINFALL_REPORT,
  VIEW_MAPPING_ACKNOWLEDGMENT,
  HEADER_CONFIGURATION,
  ADD_INSPECTION_NEW,
  REPORTS_ANALYTICS_DASHBOARD,
  REPORTS_ANALYTICS_DASHBOARD_Detail,
  REPORTS_ANALYTICS_DASHBOARD_ITEMS,
  LOGIN_VIA_OTP,
  REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
  REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item,
  REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS,
  REPORTS_ANALYTICS_LMS_DASHBOARD_ITEMS,
  ESTABLISHMENT_ADD,
  ESTABLISHMENT_LIST,
  OSHA_VIEW_REPORT_LIST,
  OSHA_VIEW_REPORT,
  OSHA_ITASUBMISSION,
  OSHA_RECORDKEEPING,
  TOOL_BOX_ATTENDEES,
  QR_LANDING_PAGE,
  QR_LANDING_PAGE_INSPECTION_REPORT,
  QR_LANDING_PAGE_RESOURCES,
  QR_LANDING_PAGE_PERMITS,
  QR_LANDING_PAGE_SAMPLINGLOGS,
  QR_LANDING_PAGE_PERMITS_DETAIL,
  QR_LANDING_PAGE_INSPECTION_VIEW,
} from "../constant/routeContant";
import IncidentListContiner from "../feature/case/containers/incidentListContiner";
import LessonsContainer from "../feature/lms/container/lessonsContainer";
import CoursesContainer from "../feature/lms/container/coursesContainer";
import OpenItemListContainer from "../feature/open-Items/openItemListContainer";
import AddCoursesContainer from "../feature/lms/container/addcoursesContainer";
import AdminTicketDashboar from "../feature/support/container/adminTicketDashboar";
import GroupsListContainer from "../feature/admin/container/groupsListContainer";
import UsersListContainer from "../feature/admin/container/usersListContainer";
import SafetyLogContainer from "../feature/admin/container/safetyLogContainer";
import ProfileContainer from "../feature/admin/container/profileContainer";
import ContactsContainer from "../feature/admin/container/contactsContainer";
import ResourceContainer from "../feature/admin/container/resourceContainer";
import UsersContainer from "../feature/admin/container/usersContainer";
import CredentialsListContainer from "../feature/credential/container/credentialsListContainer";
import AddUserCredentials from "../feature/credential/container/addUserCredentialsContainer";
import SetupCredentialListContainer from "../feature/credential/container/setupCredentialListContainer";
import AddCredentialsContainer from "../feature/credential/container/addCredentialsContainer";
import AssignCredentialConatiner from "../feature/credential/container/assignCredentialConatiner";
import ViewInspectionReport from "../feature/inspections/view-inspection-report/viewInspectionReport";
import ResolutionTicketContainer from "../feature/support/container/resolutionTicketContainer";
import AdminFaqcontainer from "../feature/support/container/adminFaqContainer";
import AddLessonsContainer from "../feature/lms/container/addlessonsContainer";
import ViewObservationReportContainer from "../feature/observation/view-observation-report/viewObservationReportContainer";
import AddContactsContainer from "../feature/admin/container/addContactsContainer";
import AddResourceContainer from "../feature/admin/container/addResourceContainer";
import UserLessonsContainer from "../feature/lms/container/userLessonsContainer";
import UserCoursesContainer from "../feature/lms/container/userCoursesContainer";
import LessonDetailContainer from "../feature/lms/container/lessonDetailContainer";
import CourseDetailContainer from "../feature/lms/container/courseDetailContainer";
import GroupsContainer from "../feature/admin/container/groupsContainer";
import CreateQuizContainer from "../feature/lms/container/createQuizContainer";
import AssetsContainer from "../feature/admin/container/assetsContainer";
import EquipmentsContainer from "../feature/admin/container/equipmentsContainer";
import VehiclesContainer from "../feature/admin/container/vehiclesContainer";
import AddAssetsContainer from "../feature/admin/container/addAssetsContainer";
import AddEquipmentsContainer from "../feature/admin/container/addEquipmentsContainer";
import AddVehiclesContainer from "../feature/admin/container/addVehiclesContainer";
import CredentialLookupContainer from "../feature/credential/container/credentialLookupContainer";
import UserQuizContainer from "../feature/lms/container/userQuizContainer";
import ObservationListContainer from "../feature/observation/observationList/observationListContainer";
import DivisionContainer from "../feature/admin/container/divisionsContainer";
import AddDivisionsContainer from "../feature/admin/container/addDivisionsContainer";
import DivisionsViewContainer from "../feature/admin/container/divisionsViewContainer";
import ProjectListContainer from "../feature/admin/container/projectListContainer";
import AddProjectContainer from "../feature/admin/container/addProjectContainer";
import AssignRoleFormContainer from "../feature/admin/container/assignRoleFormContainer";
import AddNewRoleFormContainer from "../feature/admin/container/addNewRoleFormContainer";
import AssignedUserCourseContainer from "../feature/lms/container/assignedUserCourseContainer";
import AssignedUserLessonContainer from "../feature/lms/container/assignedUserLessonContainer";
import ContactViewContainer from "../feature/admin/container/contactViewContainer";

import KudosObservationReport from "../feature/observation/view-observation-report/kudosObservationReport";
import ViolationObservationReport from "../feature/observation/view-observation-report/violationObservationReport";
import ContractorContainer from "../feature/admin/container/contractorContainer";
import ViewContractorDetailsContainer from "../feature/admin/container/viewContractorDetailsContainer";

import ContractorFormContainer from "../feature/admin/container/contractorFormContainer";
import InspectionConfigContainer from "../feature/admin/container/inspectionConfigContainer";
import InspectionCategoriesContainer from "../feature/admin/container/inspectionCategoriesContainer";

import PersonalContractorContainer from "../feature/admin/container/personalContractorContainer";
import PersonalContractorFormContainer from "../feature/admin/container/personalContractorFormContainer";
import ViewReprimandContainer from "../feature/inspections/container/viewReprimandContainer";
import ReprimandReportView from "../feature/inspections/component/reprimand/reprimandReportView";
import ViewCredentialContainer from "../feature/credential/container/viewCredentialContainer";
import ViewOpenItemReportContainer from "../feature/open-Items/viewOpenItemReportContainer";
import InspectionSubCategoriesContainer from "../feature/admin/container/inspectionSubCategoriesContainer";
import InspectionQuestionContainer from "../feature/admin/container/inspectionQuestionContainer";
import ClientListContainer from "../feature/admin/container/clientListContainer";
import AddClientContainer from "../feature/admin/container/addClientContainer";
import ViewClientContainer from "../feature/admin/container/viewClientContainer";
import CategoryAdminContainer from "../feature/admin/container/categoryAdminContainer";
import SetConfigurationContainer from "../feature/admin/container/setConfigurationContainer";
import UtilityContainer from "../feature/admin/container/UtilityContainer";
import CompanyManagementContainer from "../feature/admin/container/companyManagement";
import UserManagementContainer from "../feature/admin/container/userManagement";
import AssetManagementContainer from "../feature/admin/container/assetManagement";
import CredentialManagementContainer from "../feature/admin/container/credentialManagement";
import Terms from "../feature/terms/terms";
import ReportsContainer from "../feature/reports/containers/ReportsContainer";
import AdminCredentialListContainer from "../feature/credential/container/adminCredentialListContainer";
import AddAdminCredentialContainer from "../feature/credential/container/addAdminCredentialContainer";
import ViewAdminCredentialContainer from "../feature/credential/container/viewAdminCredentialContainer";
import UserConfigurationListContainer from "../feature/admin/container/userConfigurationListContainer";
import CasesContainer from "../feature/case/containers/casesContainer";
import CaseIncidentsList from "../feature/case/containers/caseIncidentListContainer";
import AddCaseContainer from "../feature/case/containers/addCaseContainer";
import UserConfigurationViewContainer from "../feature/admin/container/userConfigurationViewContainer";
import UserConfigurationEditContainer from "../feature/admin/container/userConfigurationEditContainer";
import ReportCredentialCountConatiner from "../feature/reports/containers/reportCredentialCountConatiner";
import AdminTicketContainer from "../feature/support/container/adminTicketContainer";
import TicketsFeedbackDesk from "../feature/support/container/ticketsFeedbackDesk";
import AdminOpenTicket from "../feature/support/component/ticket/adminOpenTicket";
import AdminOpenTicketContainer from "../feature/support/container/adminOpenTicketContainer";
import CreateTicketContainer from "../feature/support/container/createTicketContainer";
import OpenTicketContainer from "../feature/support/container/openTicketContainer";
import CreateFaqContainer from "../feature/support/container/createFaqContainer";
import FaqTopic from "../feature/support/component/faq/faqTopic";
import DetailedFaqTopic from "../feature/support/component/faq/detailedFaqTopic";
import ViewIncidentReportContainer from "../feature/case/containers/ViewIncidentReportContainer";
import SetConfigurationListContainer from "../feature/admin/container/setConfigurationListContainer";
import ReportCredentialListConatiner from "../feature/reports/containers/reportCredentialListConatiner";
import ReportCredentialViewConatiner from "../feature/reports/containers/reportCredentialViewConatiner";
import ReportSafetyDataContainer from "../feature/reports/containers/reportSafetyDataContainer";
import ReportDataContainer from "../feature/reports/containers/reportDataContainer";
import ReportTrainingSessions from "../feature/reports/component/reportTrainingSessions";
import ReportTrainingSessionsContainer from "../feature/reports/containers/reportTrainingSessionsContainer";
import ReportUtilitiesContainer from "../feature/reports/containers/reportUtilitiesContainer";

import CreateToolboxContainer from "../feature/lms/container/createToolboxContainer";
import ToolboxContainer from "../feature/lms/container/ToolboxContainer";
import ToolBoxTalkDetailContainer from "../feature/lms/container/toolBoxTalkDetailContainer";
import ToolBoxTalkAssignContainer from "../feature/lms/container/assignToolBoxTalkContainer";
import ToolBoxTalkAttendeesContainer from "../feature/lms/container/toolBoxTalkAttendeesContainer";

import ToolBoxTalkAssignUserContainer from "../feature/lms/container/assignUserToolBoxTalkContainer";
import ContractorManagementContainer from "../feature/admin/container/contractorManagementContainer";
import ContractorConfigurationList from "../feature/admin/component/users/contractor/contractorConfigurationList";
import ConrtactorConfigurationListContainer from "../feature/admin/container/conrtactorConfigurationListContainer";
import ToolBoxTalkUserContainer from "../feature/lms/container/toolBoxTalkUserContainer";

import ContractorConfigurationViewContainer from "../feature/admin/container/contractorConfigurationViewContainer";
import ContractorConfigurationEditContainer from "../feature/admin/container/contractorConfigurationEditContainer";
import NonPrivilegeUserListContainer from "../feature/admin/container/nonPrivilegeUserListContainer";
import AddNonPrivilegeUserContainer from "../feature/admin/container/addNonPrivilegeUserContainer";
import ReportSafetylogListContainer from "../feature/reports/containers/reportSafetylogListContainer";
import ToolboxScheduleUserStatusContainer from "../feature/lms/container/toolboxScheduleUserStatusContainer";
import ReportPersonallInjuryContainer from "../feature/reports/containers/reportPersonalInjuryContainer";
import AddCredentialImageContainer from "../feature/admin/container/addFilesContainer";
import ViewLookupCredentialContainer from "../feature/credential/container/viewLookupCredentialContainer";
import InspectionsTypeContainer from "../feature/storm-water/inspection/containers/inspectionsTypeContainer";
import EditInspectionsTypeContainer from "../feature/storm-water/inspection/containers/editInspectionsTypeContainer";
import SetupInspectionContainer from "../feature/storm-water/inspection/containers/setupInspectionContainer";
import QuestionConfigurationContainer from "../feature/storm-water/inspection/containers/questionConfigurationContainer";
import SetInspectionContainer from "../feature/storm-water/inspection/containers/setInspectionContainer";
import SwInspectionCategoriesContainer from "../feature/storm-water/inspection/containers/swInspectionCategoriesContainer";
import SwInspectionSubCategoriesContainer from "../feature/storm-water/inspection/containers/swInspectionSubCategoriesContainer";
import SetupSiteProjectsListContainer from "../feature/storm-water/admin/contaniners/setupSiteProjectsListContainer";
import SiteProjectListContainer from "../feature/storm-water/admin/contaniners/siteListContainer";
import AddSiteContainer from "../feature/storm-water/admin/contaniners/addSiteContainer";
import AddStormWaterProjectContainer from "../feature/storm-water/admin/contaniners/addProjectContainer";
import SwProjectListContainer from "../feature/storm-water/admin/contaniners/swProjectListContainer";
import InspectionFormsListContainer from "../feature/storm-water/inspection/containers/inspectionFormsListContainer";
import ItemsFoundResponsesContainer from "../feature/storm-water/inspection/containers/ItemsFoundResponsesContainer";
import SetupInspectionListContainer from "../feature/storm-water/inspection/containers/setupInspectionListContainer";
import ContactListContainer from "../feature/storm-water/admin/contaniners/contactListContainer";
import ViewSiteContainer from "../feature/storm-water/admin/contaniners/viewSiteContainer";
import AddContactProjectContainer from "../feature/storm-water/admin/contaniners/addContactProjectContainer";
import SwInspectionManagmentContainer from "../feature/storm-water/inspection/containers/SwInspectionManagmentContainer";
import AddPermitContainer from "../feature/storm-water/admin/contaniners/addPermitContainer";
import AddPermitListContainer from "../feature/storm-water/admin/contaniners/addPermitListContainer";
import InspectionGeneratorContainer from "../feature/storm-water/inspection/containers/inspectionGeneratorContainer";
import ConfigurePermiteeTypeContainer from "../feature/storm-water/admin/contaniners/configurePermiteeTypeContainer";
import SamplingLogContainer from "../feature/storm-water/admin/contaniners/samplingLogContainer";
import SamplingLogVersionContainer from "../feature/storm-water/admin/contaniners/samplingLogVersionContainer";
import AddReportContainer from "../feature/storm-water/inspection/containers/addReportContainer";
import ReportListContainer from "../feature/storm-water/inspection/containers/reportListContainer";
import ViewReportContainer from "../feature/storm-water/inspection/containers/viewReportContainer";
import InspectionAuthorityContainer from "../feature/storm-water/inspection/containers/InspectionAuthorityContainer";
import AddGeneralPermitContainer from "../feature/storm-water/admin/contaniners/addGeneralPermitContainer";

import JhaConfigurationContainer from "../feature/admin/container/jhaConfigurationContainer";
import JhaCategoryConfigurationContainer from "../feature/admin/container/jhaCategoryConfigurationContainer";
import JhaHazardsContainer from "../feature/admin/container/jhaHazardsContainer";
import JhaActivitiesContainer from "../feature/admin/container/jhaActivitiesContainer";
import JhaSetControlsConatiner from "../feature/admin/container/jhaSetControlsConatiner";
import JhaEditControlsContainer from "../feature/admin/container/jhaEditControlsContainer";
import ConductJhaContainer from "../feature/jha/container/conductJhaContainer";
import ConductJhaListContainer from "../feature/jha/container/conductJhaListContainer";
import JhaAddTemplateContainer from "../feature/admin/container/jhaAddTemplateContainer";
import JhaListTemplateContainer from "../feature/admin/container/jhaListTemplateContainer";
import ItemManagementContainer from "../feature/storm-water/itemManagement/container/itemManagementContainer";
import JhaReportContainer from "../feature/jha/container/jhaReportContainer";
import JhaServiceListContainer from "../feature/admin/container/jhaServiceListContainer";
import JHASetUpContainer from "../feature/admin/container/jhaSetUpContainer";
import RainFallDataContainer from "../feature/storm-water/admin/contaniners/rainFallDataContainer";
import ViewPermitContainer from "../feature/storm-water/admin/contaniners/viewPermitContainer";
import InspectionAcknowledgmentContainer from "../feature/admin/container/inspectionAcknowledgmentContainer";
import AddNewsContainer from "../feature/admin/container/addNewsContainer";
import NewsContainer from "../feature/admin/container/newsContainer";
import ViewNewsContainer from "../feature/admin/container/viewNewsContainer";
import AddInspectionProofContainer from "../feature/storm-water/inspection/containers/addInspectionProofContainer";
import ViewInspectionProofContainer from "../feature/storm-water/inspection/containers/viewInspectionProofContainer";
import DailyReportContainer from "../feature/storm-water/inspection/containers/dailyReportContainer";
import DailyReportListContainer from "../feature/storm-water/inspection/containers/dailyReportListContainer";
import SwReportsContainer from "../feature/storm-water/reports/containers/SwReportsContainer";
import SwInspectionReportsContainer from "../feature/storm-water/reports/containers/SwInspectionReportsContainer";
import AccessDeniedPage from "../library/accessDeniedPage/accessDeniedPage";
import SwSummaryReportContainer from "../feature/storm-water/reports/containers/SwSummaryReportContainer";
import SwReportMapConatiner from "../feature/storm-water/reports/containers/SwReportMapConatiner";
import UserInfoContainer from "../feature/admin/container/userInfoContainer";
import AddFilesContainer from "../feature/admin/container/addFilesContainer";
import ContractorMappingContainer from "../feature/admin/container/contractorMappingContainer";
import UserSearchMangementContainer from "../feature/admin/container/userSearchMangement";
import UserSearchByRoleContainer from "../feature/admin/container/userSearchByRoleContainer";
import ViewUserByRoleContainer from "../feature/admin/container/viewUserByRoleContainer";
import LmsClientMappingContainer from "../feature/lms/container/lmsclientmappingContainer";
import PageNotFoundPage from "../library/accessDeniedPage/pageNotFoundPage";
import ProcoreUserListContainer from "../feature/admin/container/procoreUserListContainer";
import ProcoreProjectListContainer from "../feature/admin/container/procoreProjectListContainer";
import ViewProcoreProjectContainer from "../feature/admin/container/viewProcoreProjectContainer";
import ViewUserContainer from "../feature/admin/container/viewUserContainer";
import SwRainfallReportContainer from "../feature/storm-water/reports/containers/swRainfallReportContainer";
import ViewStatementMappingContainer from "../feature/admin/container/viewStatementMappingContainer";
import HeaderConfigurationContainer from "../feature/admin/container/HeaderConfigurationContainer";
import AddInspectionNewContainer from "../feature/inspections-new/container/addInspectionContainer";
import ReportAnalyticsTabContainer from "../feature/report-analytics/container/reportAnalyticsTabContainer";
import DrillDownContainer from "../feature/report-analytics/inspections/container/drillDownContainer";
import DrillDownContainerLms from "../feature/report-analytics/lms/container/drillDownContainer";
import ItemsContainer from "../feature/report-analytics/inspections/container/itemsContainer";
import DrillDownIncidentContainer from "../feature/report-analytics/incidents/container/drillDownIncidentContainer";
//import ItemIncidents from "../feature/report-analytics/incidents/component/itemIncidents";
import ItemsIncidentContainer from "../feature/report-analytics/incidents/container/itemsIncidentContainer";
import ItemsContainerLMS from "../feature/report-analytics/lms/container/itemsContainerLMS";
import AddEstablishmentContainer from "../feature/osha-management/container/establishmentAddContainer";
import EstablishmentListContainer from "../feature/osha-management/container/establishmentListContainer";
import OshaViewReportListContainer from "../feature/osha-management/container/oshaViewReportListContainer";
import OshaViewReportContainer from "../feature/osha-management/container/oshaViewReportContainer";
import OshaRecordkeepingContainer from "../feature/osha-management/container/oshaRecordkeepingContainer";
import OshaItaSubmissionContainer from "../feature/osha-management/container/oshaItaSubmissionContainer";
import ViewAttendesContainer from "../feature/lms/container/viewAttendesContainer";
import SequenceLandingContainer from "../sequenceLanding/container/sequenceLandingContainer";
import SequenceInspectionReportContainer from "../sequenceLanding/container/sequenceInspectionReportContainer";
import SequenceResourcesContainer from "../sequenceLanding/container/sequenceResourcesContainer";
import SequencePermitsContainer from "../sequenceLanding/container/sequencePermitsContainer";
import SequenceSamplingLogContainer from "../sequenceLanding/container/sequenceSamplingLogContainer";
import SequencePermitsDetailContainer from "../sequenceLanding/container/sequencePermitsDetailContainer";
import SequenceInspectionViewContainer from "../sequenceLanding/container/sequenceInspectionViewContainer";

const MainRouter = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <>
      <Routes>
        {/* private */}
        <Route element={<PrivateRoutes />}>
          {/* Admin */}
          <Route path={ADMIN_GROUP} element={<GroupsListContainer />} />
          <Route path={ADD_ADMIN_USER} element={<UsersContainer />} />
          <Route path={ADD_ADMIN_GROUP} element={<GroupsContainer />} />
          <Route path={ADMIN_USER} element={<UsersListContainer />} />
          <Route path={ADMIN_VEHICLES} element={<VehiclesContainer />} />
          <Route path={ADMIN_EQUIPMENTS} element={<EquipmentsContainer />} />
          <Route path={ADMIN_ASSETS} element={<AssetsContainer />} />
          <Route path={ADMIN_ADD_VEHICLES} element={<AddVehiclesContainer />} />
          <Route path={ADMIN_CLIENT_LIST} element={<ClientListContainer />} />
          <Route path={ADMIN_ADD_CLIENT} element={<AddClientContainer />} />
          <Route path={ADMIN_VIEW_CLIENT} element={<ViewClientContainer />} />
          <Route path={ADMIN_USER_INFO} element={<UserInfoContainer />} />
          <Route
            path={ADMIN_USER_CONFIGURATION}
            element={<UserConfigurationListContainer />}
          />
          <Route
            path={ADMIN_NONPRIVILEGE_USER_LIST}
            element={<NonPrivilegeUserListContainer />}
          />
          <Route
            path={ADD_ADMIN_NONPRIVILEGE_USER}
            element={<AddNonPrivilegeUserContainer />}
          />
          <Route
            path={USER_CONFIGURATION_VIEW}
            element={<UserConfigurationViewContainer />}
          />
          <Route
            path={USER_ONBOARD_EDIT}
            element={<UserConfigurationEditContainer />}
          />
          {/* Credential */}
          <Route path={MY_CREDENTIALS} element={<CredentialsListContainer />} />
          <Route
            path={MY_USER_ADD_CREDENTIAL}
            element={<AddUserCredentials />}
          />
          <Route path={VIEW_CREDENTIAL} element={<ViewCredentialContainer />} />
          <Route
            path={SETUP_CREDENTIAL}
            element={<SetupCredentialListContainer />}
          />
          <Route
            path={ADMIN_CREDENTIAL}
            element={<AdminCredentialListContainer />}
          />
          <Route
            path={ADMIN_ADD_CREDENTIAL}
            element={<AddAdminCredentialContainer />}
          />
          <Route
            path={VIEW_ADMIN_CREDENTIAL}
            element={<ViewAdminCredentialContainer />}
          />
          <Route
            path={SETUP_ADD_CREDENTIAL}
            element={<AddCredentialsContainer />}
          />
          <Route
            path={CREDENTIAL_LOOKUP}
            element={<CredentialLookupContainer />}
          />
          <Route
            path={VIEW_LOOKUP_CREDENTIAL}
            element={<ViewLookupCredentialContainer />}
          />
          <Route
            path={ASSIGN_CREDENTIAL}
            element={<AssignCredentialConatiner />}
          />
          <Route
            path={ADMIN_ASSIGN_ROLE}
            element={<AssignRoleFormContainer />}
          />
          <Route
            path={ADMIN_ADD_NEW_ROLE}
            element={<AddNewRoleFormContainer />}
          />
          <Route
            path={ADMIN_EDIT_NEW_ROLE}
            element={<AddNewRoleFormContainer />}
          />
          <Route
            path={ADMIN_ADD_EQUIPMENTS}
            element={<AddEquipmentsContainer />}
          />
          <Route
            path={ADMIN_EDIT_EQUIPMENT}
            element={<AddEquipmentsContainer />}
          />
          <Route path={ADMIN_ADD_ASSETS} element={<AddAssetsContainer />} />
          <Route path={ADMIN_SAFETY_LOG} element={<SafetyLogContainer />} />
          <Route path={ADMIN_PROFILE} element={<ProfileContainer />} />
          <Route path={ADMIN_RESOURCE} element={<ResourceContainer />} />
          <Route path={ADMIN_CONTACTS} element={<ContactsContainer />} />
          <Route path={ADMIN_ADD_CONTACTS} element={<AddContactsContainer />} />
          <Route path={ADMIN_EDIT_CONTACT} element={<AddContactsContainer />} />
          <Route path={ADMIN_VIEW_CONTACT} element={<ContactViewContainer />} />
          <Route path={ADMIN_ADD_RESOURCE} element={<AddResourceContainer />} />
          <Route
            path={ADMIN_EDIT_RESOURCE}
            element={<AddResourceContainer />}
          />
          <Route path={ADMIN_DIVISION} element={<DivisionContainer />} />
          <Route
            path={ADMIN_VIEW_DIVISION}
            element={<DivisionsViewContainer />}
          />
          <Route
            path={ADMIN_ADD_DIVISION}
            element={<AddDivisionsContainer />}
          />
          <Route path={ADMIN_PROJECT} element={<ProjectListContainer />} />
          <Route
            path={ADMIN_EDIT_DIVISION}
            element={<AddDivisionsContainer />}
          />
          <Route path={ADMIN_ADD_PROJECT} element={<AddProjectContainer />} />
          <Route
            path={INSPECTION_ADMIN}
            element={<InspectionConfigContainer />}
          />
          <Route
            path={INSPECTION_CATEGORY}
            element={<InspectionCategoriesContainer />}
          />
          <Route
            path={INSPECTION_SUB_CATEGORY}
            element={<InspectionSubCategoriesContainer />}
          />
          <Route
            path={INSPECTION_QUESTIONS}
            element={<InspectionQuestionContainer />}
          />
          <Route
            path={INSPECTION_SET}
            element={<SetConfigurationContainer />}
          />
          {/* Incident New */}
          <Route path={CASES} element={<CasesContainer />} />
          <Route path={CASEINCIDENTS} element={<CaseIncidentsList />} />
          <Route path={ADDCASE} element={<AddCaseContainer />} />
          <Route path={EDITCASE} element={<AddCaseContainer />} />
          <Route
            path={INSPECTIONS}
            element={<SetConfigurationListContainer />}
          />
          <Route
            path={INSPECTIONS_ACKNOWLEDGMENT}
            element={<InspectionAcknowledgmentContainer />}
          />
          <Route
            path={VIEW_MAPPING_ACKNOWLEDGMENT}
            element={<ViewStatementMappingContainer />}
          />
          <Route
            path={HEADER_CONFIGURATION}
            element={<HeaderConfigurationContainer />}
          />
          {/* Incident */}
          <Route path={ONBOARDING} element={<Onboarding />} />
          <Route path={DASHBOARD} element={<Dashboard />} />
          <Route path={SWDASHBOARD} element={<SwDashboard />} />
          {/* <Route path={CASE_LIST} element={<CaseListContainer />} /> */}
          <Route path={PERSONAL_INJURY} element={<PersonalInjuryContainer />} />
          <Route path={VEHICLE_ACCIDENT} element={<AddVehicleContainer />} />
          <Route path={UTILITY_DAMAGE} element={<UtilityDamageContainer />} />
          <Route path={STOLEN_PROPERTY} element={<StolenPropertyContainer />} />
          <Route path={NEAR_MISS} element={<NearMissesContainer />} />
          <Route path={LANDING_PAGE} element={<Landingpage />} />
          <Route
            path="/incidentlist/"
            element={<CaseIncidentListContainer />}
          />
          <Route
            path="/view-case/view-incident"
            element={<ViewIncidentReportContainer />}
          />
          <Route
            path={ADD_INSPECTION_NEW}
            element={<AddInspectionNewContainer />}
          />
          {/* inspection */}
          <Route path="/inspection" element={<AddInspectionContainer />}>
            <Route path=":id" element={<BasicInspection />}>
              <Route path="edit/:id" element={<BasicInspection />} />
            </Route>
          </Route>
          <Route
            path="/inspection/view/report"
            element={<InspectionViewReport />}
          />
          <Route
            path={INSPECTION_REPORT}
            element={<ViewInspectionReport />}
          ></Route>
          <Route path={SUPPORT} element={<SupportContainer />} />
          <Route path={TICKET} element={<TicketContainer />} />
          <Route path={CREATE_TICKET} element={<CreateTicketContainer />} />
          <Route path={TICKET_DASHBOARD} element={<AdminTicketDashboar />} />
          <Route path={ADMIN_TICKET} element={<AdminTicketContainer />} />
          <Route path={USER_TICKET_DETAILS} element={<OpenTicketContainer />} />
          <Route
            path={ADMIN_TICKET_DETAILS}
            element={<AdminOpenTicketContainer />}
          />
          <Route
            path={ADMIN_FEEDBACK_TICKET}
            element={<TicketsFeedbackDesk />}
          />
          <Route
            path={RESOLUTION_TEAM_TICKET}
            element={<ResolutionTicketContainer />}
          />
          <Route path={FAQ} element={<Faq />} />
          <Route path={FAQ_TOPIC} element={<FaqTopic />} />
          <Route path={FAQ_DETAIL} element={<DetailedFaqTopic />} />
          <Route path={ADMIN_FAQ} element={<AdminFaqcontainer />} />
          <Route path={CREATE_FAQ} element={<CreateFaqContainer />} />
          <Route
            path="/view-incident-report"
            element={<IncidentReportListContainer />}
          />
          <Route path={REPRIMAND} element={<AddReprimandContainer />}>
            <Route path={"edit/:id"} element={<AddReprimandContainer />} />
          </Route>
          <Route path={REPRIMAND_LIST} element={<ReprimandListContiner />} />
          <Route path={OBSERVATION} element={<AddObservationContainer />} />
          <Route
            path={VIEW_REPRIMAND_CONTAINER}
            element={<ViewReprimandContainer />}
          />
          <Route
            path={VIEW_OBSERVATION_DETAILS}
            element={<ViewObservationReportContainer />}
          />
          <Route
            path={VIEW_OBSERVATION_LISTS}
            element={<ObservationListContainer />}
          />
          <Route path={VIEW_KUDOS} element={<KudosObservationReport />} />
          <Route path={VIEW_REPRIMAND} element={<ReprimandReportView />} />
          <Route
            path={VIEW_VIOLATION}
            element={<ViolationObservationReport />}
          />
          <Route path={INCIDENT_LIST} element={<IncidentListContiner />} />
          <Route path="/connect" element={<Connect />} />
          <Route path={CREATE_COURSE} element={<AddCoursesContainer />}>
            <Route path={"edit/:id"} element={<AddCoursesContainer />} />
          </Route>
          <Route path={CREATE_LESSON} element={<AddLessonsContainer />}>
            <Route path={"edit/:id"} element={<AddLessonsContainer />} />
          </Route>
          <Route path={LESSON} element={<LessonsContainer />} />
          <Route path={COURSES} element={<CoursesContainer />} />
          <Route path={OPEN_ITEMS_LIST} element={<OpenItemListContainer />} />
          <Route
            path={VIEW_OPEN_ITEMS_DETAILS}
            element={<ViewOpenItemReportContainer />}
          />
          <Route path={USERCOURSES} element={<UserCoursesContainer />} />
          <Route path={USERLESSON} element={<UserLessonsContainer />} />
          <Route path={LESSONDETAIL} element={<LessonDetailContainer />} />
          <Route path={COURSESDETAIL} element={<CourseDetailContainer />} />
          <Route path={CREATE_QUIZ} element={<CreateQuizContainer />} />
          <Route path={USER_QUIZ} element={<UserQuizContainer />} />
          <Route
            path={LMS_CLIENT_LESSON_MAPPING}
            element={<LmsClientMappingContainer />}
          />
          {/* contractor */}
          <Route
            path={ADMIN_CONTRACTOR_CONFIGURATION_EDIT}
            element={<ContractorConfigurationEditContainer />}
          />
          <Route
            path={ADMIN_CONTRACTOR_CONFIGURATION_VIEW}
            element={<ContractorConfigurationViewContainer />}
          />
          <Route
            path={ADMIN_CONTRACTOR_CONFIGURATION_LIST}
            element={<ConrtactorConfigurationListContainer />}
          />
          <Route
            path={ADMIN_CONTRACTOR_MANAGEMENT}
            element={<ContractorManagementContainer />}
          />
          <Route
            path={ADMIN_CONTRACTOR_LIST}
            element={<ContractorContainer />}
          />
          <Route
            path={ADMIN_CONTRACTOR}
            element={<ContractorFormContainer />}
          />
          <Route
            path={USER_ASSIGNED}
            element={<AssignedUserCourseContainer />}
          />
          <Route
            path={ADMIN_PERSONAL_CONTRACTOR}
            element={<PersonalContractorFormContainer />}
          />
          <Route
            path={ADMIN_PERSONAL_CONTRACTOR_LIST}
            element={<PersonalContractorContainer />}
          />
          <Route
            path={USER_ASSIGNED_LESSON}
            element={<AssignedUserLessonContainer />}
          />
          <Route
            path={ADMIN_CONTRACTOR_DETAILS}
            element={<ViewContractorDetailsContainer />}
          />
          <Route
            path={ADMIN_CONTRACTOR_MAPPING}
            element={<ContractorMappingContainer />}
          />
          <Route
            path={ADMIN_CATEGORY_CREDENTIALS}
            element={<CategoryAdminContainer />}
          />
          <Route path={ADMIN_UTILITY} element={<UtilityContainer />} />
          <Route
            path={ADMIN_CATEGORY_LMS}
            element={<CategoryAdminContainer />}
          />
          <Route
            path={ADMIN_CATEGORY_SUPPORT}
            element={<CategoryAdminContainer />}
          />
          <Route
            path={ADMIN_CATEGORY_INCIDENT}
            element={<CategoryAdminContainer />}
          />
          <Route
            path={ADMIN_CATEGORY_RESOURSE}
            element={<CategoryAdminContainer />}
          />
          <Route
            path={ADMIN_CATEGORY_ASSETS}
            element={<CategoryAdminContainer />}
          />
          <Route
            path={ADMIN_CATEGORY_CREDENTIALSS}
            element={<CategoryAdminContainer />}
          />
          <Route
            path={ADMIN_COMPANY_MANAGEMENT}
            element={<CompanyManagementContainer />}
          />
          <Route
            path={ADMIN_USER_MANAGEMENT}
            element={<UserManagementContainer />}
          />
          <Route
            path={ADMIN_USER_SEARCH}
            element={<UserSearchMangementContainer />}
          />
          <Route
            path={ADMIN_USER_SEARCH_BY_ROLE}
            element={<UserSearchByRoleContainer />}
          />
          <Route
            path={ADMIN_USER_VIEW_BY_ROLE}
            element={<ViewUserByRoleContainer />}
          />
          <Route
            path={ADMIN_PROCORE_USER_LIST}
            element={<ProcoreUserListContainer />}
          />
          <Route
            path={ADMIN_PROCORE_PROJECT_LIST}
            element={<ProcoreProjectListContainer />}
          />
          <Route
            path={ADMIN_VIEW_PROCORE_PROJECT}
            element={<ViewProcoreProjectContainer />}
          />
          <Route path={ADMIN_VIEW_USER} element={<ViewUserContainer />} />
          <Route
            path={ESTABLISHMENT_LIST}
            element={<EstablishmentListContainer />}
          />
          <Route
            path={ESTABLISHMENT_ADD}
            element={<AddEstablishmentContainer />}
          />
          <Route
            path={OSHA_VIEW_REPORT_LIST}
            element={<OshaViewReportListContainer />}
          />
          <Route
            path={OSHA_VIEW_REPORT}
            element={<OshaViewReportContainer />}
          />
          <Route
            path={OSHA_RECORDKEEPING}
            element={<OshaRecordkeepingContainer />}
          />
          <Route
            path={OSHA_ITASUBMISSION}
            element={<OshaItaSubmissionContainer />}
          />

          {/* strom-water */}
          <Route
            path={ADMIN_SITE_PROJECTS_MANAGEMENT}
            element={<SetupSiteProjectsListContainer />}
          />
          <Route
            path={ADMIN_SITE_PROJECTS_LIST}
            element={<SiteProjectListContainer />}
          />
          <Route
            path={ADMIN_PROJECTS_LIST}
            element={<SwProjectListContainer />}
          />
          <Route path={ADMIN_ADD_SITE} element={<AddSiteContainer />} />
          <Route path={ADMIN_VIEW_SITE} element={<ViewSiteContainer />} />
          <Route
            path={ADMIN_ASSET_MANAGEMENT}
            element={<AssetManagementContainer />}
          />
          <Route
            path={ADMIN_CREDENTIAL_MANAGEMENT}
            element={<CredentialManagementContainer />}
          />
          <Route path={ADMIN_ADD_FILES} element={<AddFilesContainer />} />
          <Route
            path={ADMIN_ADD_STORM_WATERPROJECT}
            element={<AddStormWaterProjectContainer />}
          />
          {/*reports-analytics */}
          <Route path={REPORTS_ANALYTICS} element={<ReportsContainer />} />
          <Route path={SW_REPORTS_ANALYTICS} element={<SwReportsContainer />} />
          <Route
            path={STROM_WATER_ANALYTICS_REPORT_INSPECTION}
            element={<SwInspectionReportsContainer />}
          />
          <Route
            path={SW_SUMMARY_REPORT}
            element={<SwSummaryReportContainer />}
          />
          <Route
            path={SW_RAINFALL_REPORT}
            element={<SwRainfallReportContainer />}
          />
          <Route path={SW_MAP_VIEW_REPORT} element={<SwReportMapConatiner />} />
          <Route
            path={REPORTS_CREDENTIAL_COUNT}
            element={<ReportCredentialCountConatiner />}
          />
          <Route
            path={REPORTS_CREDENTIAL_LIST}
            element={<ReportCredentialListConatiner />}
          />
          <Route
            path={REPORTS_SAFETYLOG_LIST}
            element={<ReportSafetylogListContainer />}
          />
          <Route
            path={REPORTS_PERSONAL_INJURY}
            element={<ReportPersonallInjuryContainer />}
          />
          <Route path={TOOL_BOX_CREATE} element={<CreateToolboxContainer />} />
          <Route path={TOOL_BOX_LIST} element={<ToolboxContainer />} />
          <Route
            path={REPORTS_CREDENTIAL_VIEW}
            element={<ReportCredentialViewConatiner />}
          />
          <Route
            path={REPORTS_SAFETY_VIEW}
            element={<ReportSafetyDataContainer />}
          />
          <Route path={REPORTS_DATA} element={<ReportDataContainer />} />
          <Route
            path={REPORTS_TRAINING_SESSIONS}
            element={<ReportTrainingSessionsContainer />}
          />
          <Route
            path={REPORTS_UTILITIES}
            element={<ReportUtilitiesContainer />}
          />
          <Route
            path={TOOL_BOX_DETAIL}
            element={<ToolBoxTalkDetailContainer />}
          />
          <Route
            path={TOOL_BOX_ASSIGN}
            element={<ToolBoxTalkAssignContainer />}
          />
          <Route
            path={TOOL_BOX_VERIFY_ATTENDEES}
            element={<ToolBoxTalkAttendeesContainer />}
          />
          <Route
            path={TOOL_BOX_ASSIGN_USER}
            element={<ToolBoxTalkAssignUserContainer />}
          />
          <Route path={TOOL_BOX_USER} element={<ToolBoxTalkUserContainer />} />
          <Route
            path={TOOL_BOX_USERCOUNT_ASSIGN}
            element={<ToolboxScheduleUserStatusContainer />}
          />

          <Route
            path={TOOL_BOX_ATTENDEES}
            element={<ViewAttendesContainer />}
          />
          <Route
            path={REPORTS_ANALYTICS_DASHBOARD}
            element={<ReportAnalyticsTabContainer />}
          />
          <Route
            path={REPORTS_ANALYTICS_DASHBOARD_Detail}
            element={<DrillDownContainer />}
          />
          <Route
            path={REPORTS_ANALYTICS_LMS_DASHBOARD_DETAILS}
            element={<DrillDownContainerLms />}
          />
          <Route
            path={REPORTS_ANALYTICS_LMS_DASHBOARD_ITEMS}
            element={<ItemsContainerLMS/>}
          />

          <Route
            path={REPORTS_ANALYTICS_DASHBOARD_ITEMS}
            element={<ItemsContainer />}
          />
          <Route
            path={REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail}
            element={<DrillDownIncidentContainer />}
          />
          <Route
            path={REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item}
            element={<ItemsIncidentContainer />}
          />

          {/* // storm water  */}
          <Route path={SW_CONTACT_LIST} element={<ContactListContainer />} />
          <Route
            path={SW_CONTACT_PROJECT}
            element={<AddContactProjectContainer />}
          />
          <Route path={SW_ADD_PERMIT} element={<AddPermitContainer />} />
          <Route path={SW_EDIT_PERMIT} element={<AddPermitContainer />} />
          <Route path={SW_PERMIT_LIST} element={<AddPermitListContainer />} />
          <Route
            path={SW_INSPECTION_CONFIGURATION}
            element={<SwInspectionManagmentContainer />}
          />
          <Route
            path={INSPECTION_GENERATOR}
            element={<InspectionGeneratorContainer />}
          />
          <Route
            path={INSPECTION_TYPE}
            element={<InspectionsTypeContainer />}
          />
          <Route
            path={CONFIGURE_EDIT_INSPECTION}
            element={<EditInspectionsTypeContainer />}
          />
          <Route
            path={SETUP_INSPECTION}
            element={<SetupInspectionContainer />}
          />
          <Route
            path={LINK_QUESTION_INSPECTION}
            element={<QuestionConfigurationContainer />}
          />
          <Route
            path={INSPECTION_AUTHORITY}
            element={<InspectionAuthorityContainer />}
          />
          <Route
            path={ADD_INSPECTION_FORM}
            element={<SetInspectionContainer />}
          />
          <Route
            path={INSPECTION_FORM_LIST}
            element={<InspectionFormsListContainer />}
          />
          <Route
            path={SETUP_INSPECTION_LIST}
            element={<SetupInspectionListContainer />}
          />
          <Route
            path={ITEM_FOUND_RESPONSES}
            element={<ItemsFoundResponsesContainer />}
          />
          <Route
            path={SW_CATEGORIES}
            element={<SwInspectionCategoriesContainer />}
          />
          <Route
            path={SW_SUB_CATEGORIES}
            element={<SwInspectionSubCategoriesContainer />}
          />
          <Route
            path={ADMIN_JHA_CONFIGURATION}
            element={<JhaConfigurationContainer />}
          />
          <Route
            path={ADMIN_JHA_CATEGORY}
            element={<JhaCategoryConfigurationContainer />}
          />
          <Route path={ADMIN_JHA_HAZARDS} element={<JhaHazardsContainer />} />
          <Route
            path={ADMIN_JHA_ACTIVITIES}
            element={<JhaActivitiesContainer />}
          />
          <Route
            path={ADMIN_JHA_SET_CONTROLS}
            element={<JhaSetControlsConatiner />}
          />
          <Route
            path={ADMIN_JHA_EDIT_CONTROLS}
            element={<JhaEditControlsContainer />}
          />
          <Route
            path={SW_CONFIGURE_PERMITEE_TYPE}
            element={<ConfigurePermiteeTypeContainer />}
          />
          <Route
            path={SW_ADMIN_SAMPLINGLOG}
            element={<SamplingLogContainer />}
          />
          <Route
            path={SW_ADMIN_SAMPLINGLOG_HISTORY}
            element={<SamplingLogVersionContainer />}
          />
          <Route path={SW_INSPECTION_ADD} element={<AddReportContainer />} />
          <Route
            path={SW_INSPECTION_PROOF}
            element={<AddInspectionProofContainer />}
          />
          <Route
            path={SW_INSPECTION_PROOF_VIEW}
            element={<ViewInspectionProofContainer />}
          />
          <Route
            path={SW_INSPECTION_DAILY_VIEW}
            element={<DailyReportListContainer />}
          />
          <Route path={SW_DAILY_REPORT} element={<DailyReportContainer />} />
          <Route path={SW_INSPECTION_VIEW} element={<ViewReportContainer />} />
          <Route path={SW_INSPECTION_LIST} element={<ReportListContainer />} />
          <Route
            path={SW_GENERAL_PERMIT}
            element={<AddGeneralPermitContainer />}
          />
          <Route path={CONDUCT_JHA} element={<ConductJhaContainer />} />
          <Route
            path={CONDUCT_JHA_LIST}
            element={<ConductJhaListContainer />}
          />
          <Route
            path={ADMIN_JHA_LIST_TEMPLATE}
            element={<JhaListTemplateContainer />}
          />
          <Route
            path={ADMIN_JHA_ADD_TEMPLATE}
            element={<JhaAddTemplateContainer />}
          />
          <Route path={SW_OPEN_ITEMS} element={<ItemManagementContainer />} />
          <Route
            path={ADMIN_JHA_LIST_SERVICE}
            element={<JhaServiceListContainer />}
          />
          <Route path={ADMIN_JHA_SETUP} element={<JHASetUpContainer />} />
          <Route path={CONDUCT_JHA_REPORT} element={<JhaReportContainer />} />
          <Route path={SW_RAIN_FALL_DATA} element={<RainFallDataContainer />} />
          <Route path={SW_VIEW_PERMIT} element={<ViewPermitContainer />} />
          <Route path={ADMIN_ADD_NEWS} element={<AddNewsContainer />} />
          <Route path={ADMIN_LIST_NEWS} element={<NewsContainer />} />
          <Route path={NEWSDETAIL} element={<ViewNewsContainer />} />
          <Route path={ACCESS_DENIED} element={<AccessDeniedPage />} />
          <Route path={PAGE_NOT_FOUND} element={<PageNotFoundPage />} />

          <Route path="*" element={<Navigate replace to={PAGE_NOT_FOUND} />} />
        </Route>

        {/* public router */}
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path={LOGIN_VIA_OTP} element={<LoginViaOtp />} />
          <Route path="/verify-mobile" element={<VerifyMobile />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/terms-of-conditions" element={<Terms />} />
          <Route
            path={QR_LANDING_PAGE}
            element={<SequenceLandingContainer />}
          />
          <Route
            path={QR_LANDING_PAGE_INSPECTION_REPORT}
            element={<SequenceInspectionReportContainer />}
          />
          <Route
            path={QR_LANDING_PAGE_RESOURCES}
            element={<SequenceResourcesContainer />}
          />
          <Route
            path={QR_LANDING_PAGE_PERMITS}
            element={<SequencePermitsContainer />}
          />
          <Route
            path={QR_LANDING_PAGE_SAMPLINGLOGS}
            element={<SequenceSamplingLogContainer />}
          />
          <Route
            path={QR_LANDING_PAGE_PERMITS_DETAIL}
            element={<SequencePermitsDetailContainer />}
          />
          <Route
            path={QR_LANDING_PAGE_INSPECTION_VIEW}
            element={<SequenceInspectionViewContainer />}
          />
        </Route>
      </Routes>
    </>
  );
};
export default MainRouter;
