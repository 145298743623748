export const toolBoxTalkType = {
  POST_TOOL_BOX_TALK_PENDING: "[TOOL_BOX_TALK][REQUEST] Create Tool Box TALK",
  POST_TOOL_BOX_TALK_FAILURE:
    "[TOOL_BOX_TALK][RESPONSE] Tool Box Talk Creation Failed",
  POST_TOOL_BOX_TALK_SUCCESS:
    "[TOOL_BOX_TALK][RESPONSE] Tool Box Talk Created Successfull",
  CREATE_PAGE: "CreateToolBoxTalkPage",

  UPDATE_TOOL_BOX_TALK_PENDING: "UPDATE_TOOL_BOX_TALK_PENDING",
  UPDATE_TOOL_BOX_TALK_SUCCESS: "UPDATE_TOOL_BOX_TALK_SUCCESS",
  UPDATE_TOOL_BOX_TALK_FAILED: "UPDATE_TOOL_BOX_TALK_FAILED",
  UPDATE_PAGE: "UpdatePage",

  GET_TOOL_BOX_TALK_LIST_PENDING:
    "[GET_TOOL_BOX_TALK_LIST][REQUEST] Get Instructor Tool Box Talk list requested",
  GET_TOOL_BOX_TALK_LIST_FAILURE:
    "[GET_TOOL_BOX_TALK_LIST][RESPONSE] Get Instructor Tool Box Talk list Failed",
  GET_TOOL_BOX_TALK_LIST_SUCCESS:
    "[GET_TOOL_BOX_TALK_LIST][RESPONSE] Get  Instructor Tool Box Talk list Successfull",
  TOOLBOXTALK_PAGE: "InstructorToolboxtalkPage",

  GET_ADMINTOOL_BOX_TALK_LIST_PENDING:
    "[GET_ADMINTOOL_BOX_TALK_LIST_PENDING][REQUEST] Get Admin Tool Box Talk list requested",
  GET_ADMINTOOL_BOX_TALK_LIST_FAILURE:
    "[GET_ADMINTOOL_BOX_TALK_LIST_FAILURE][RESPONSE] Get Admin Tool Box Talk list Failed",
  GET_ADMINTOOL_BOX_TALK_LIST_SUCCESS:
    "[GET_ADMINTOOL_BOX_TALK_LIST_SUCCESS][RESPONSE] Get Admin Tool Box Talk list Successfull",
  ADMINTOOLBOXTALK_PAGE: "AdminToolboxtalkPage",

  GET_TOOL_BOX_TALK_DETAILS_PENDING: "GET_TOOL_BOX_TALK_DETAILS_PENDING",
  GET_TOOL_BOX_TALK_DETAILS_SUCCESS: "GET_TOOL_BOX_TALK_DETAILS_SUCCESS",
  GET_TOOL_BOX_TALK_DETAILS_FAILED: "GET_TOOL_BOX_TALK_DETAILS_FAILED",

  DELETE_TOOL_BOX_TALK_PENDING: "DELETE_TOOL_BOX_TALK_PENDING",
  DELETE_TOOL_BOX_TALK_SUCCESS: "DELETE_TOOL_BOX_TALK_SUCCESS",
  DELETE_TOOL_BOX_TALK_FAILED: "DELETE_TOOL_BOX_TALK_FAILED",
  CLEAR_TOOLTALK: "[CLEAR_TOOLTALK][REQUEST] Clear TOOL TALK",
  TOOLBOXTALK_DELETE_PAGE: "ToolboxtalkDeletePage",

  GET_TOOL_BOX_SAFETY_MEETING_SEARCH_PENDING:
    "[GET_TOOL_BOX_SAFETY_MEETING_SEARCH][REQUEST] Get Tool Box Talk Search list requested",
  GET_TOOL_BOX_SAFETY_MEETING_SEARCH_FAILURE:
    "[GET_TOOL_BOX_SAFETY_MEETING_SEARCH][RESPONSE] Get Tool Box Talk list Search Failed",
  GET_TOOL_BOX_SAFETY_MEETING_SEARCH_SUCCESS:
    "[GET_TOOL_BOX_SAFETY_MEETING_SEARCH][RESPONSE] Get Tool Box Talk list  Search Successfull",
  TOOLBOXSAFETYSEARCH_PAGE: "ToolboxtalkSearchMeetingPage",

  POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_PENDING:
    "[POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_PENDING][REQUEST] Assign Admin to instructor Tool Box TALK",
  POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_FAILURE:
    "[POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_FAILURE][RESPONSE] Assign Admin to instructor Tool Box Talk Creation Failed",
  POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_SUCCESS:
    "[POST_TOOL_BOX_ASSIGN_TO_INSTRUCTOR_SUCCESS][RESPONSE] Assign Admin to instructor Tool Box Talk Created Successfull",
  CREATE_TOOL_ASSIGNPAGE: "AssignToInstructorToolPage",

  GET_SCHEDULE_MEETING_DETAILS_PENDING: "GET_SCHEDULE_MEETING_DETAILS_PENDING",
  GET_SCHEDULE_MEETING_DETAILS_SUCCESS: "GET_SCHEDULE_MEETING_DETAILS_SUCCESS",
  GET_SCHEDULE_MEETING_DETAILS_FAILED: "GET_SCHEDULE_MEETING_DETAILS_FAILED",
  SCHEDULE_DETAIL_PAGE: "ScheduleDetailPage",
  CLEAR_SCHEDULETOOLTALK:
    "[CLEAR_SCHEDULETOOLTALK][REQUEST] Clear SCHEDULE TOOL TALK",

  POST_TOOL_BOX_ASSIGN_TO_USER_PENDING:
    "[POST_TOOL_BOX_ASSIGN_TO_USER_PENDING][REQUEST] Assign  instructor To User Tool Box TALK",
  POST_TOOL_BOX_ASSIGN_TO_USER_FAILURE:
    "[POST_TOOL_BOX_ASSIGN_TO_USER_FAILURE][RESPONSE] Assign instructor To User Tool Box Talk Creation Failed",
  POST_TOOL_BOX_ASSIGN_TO_USER_SUCCESS:
    "[POST_TOOL_BOX_ASSIGN_TO_USER_SUCCESS][RESPONSE] Assign instructor To User Tool Box Talk Created Successfull",
  CREATE_TOOL_ASSIGNUSERPAGE: "AssignToUserToolPage",

  POST_TOOL_BOX_SELF_ASSIGN_TO_USER_PENDING:
    "[POST_TOOL_BOX_SELF_ASSIGN_TO_USER_PENDING][REQUEST] Assign  self instructor To User Tool Box TALK",
  POST_TOOL_BOX_SELF_ASSIGN_TO_USER_FAILURE:
    "[POST_TOOL_BOX_SELF_ASSIGN_TO_USER_FAILURE][RESPONSE] Assign self instructor To User Tool Box Talk Creation Failed",
  POST_TOOL_BOX_SELF_ASSIGN_TO_USER_SUCCESS:
    "[POST_TOOL_BOX_SELF_ASSIGN_TO_USER_SUCCESS][RESPONSE] Assign self instructor To User Tool Box Talk Created Successfull",
  CREATE_TOOL_SELFASSIGNUSERPAGE: "SelfAssignToUserToolPage",

  POST_VERIFY_ATTENDEE_PENDING:
    "[POST_VERIFY_ATTENDEE_PENDING][REQUEST] Verrify Attendee Pending",
  POST_VERIFY_ATTENDEE_FAILURE:
    "[POST_VERIFY_ATTENDEE_FAILURE][RESPONSE] Verrify Attendee Creation Failed",
  POST_VERIFY_ATTENDEE_SUCCESS:
    "[POST_VERIFY_ATTENDEE_SUCCESS][RESPONSE] Verrify Attendee  Successfull",
  CREATE_VERIFY_PAGE: "VerifyPage",

  GET_VERIFY_ATTENDEE_LIST_PENDING:
    "[GET_VERIFY_ATTENDEE_LIST_PENDING][REQUEST] Get Verrify Attendee list requested",
  GET_VERIFY_ATTENDEE_LIST_FAILURE:
    "[GET_VERIFY_ATTENDEE_LIST_FAILURE][RESPONSE] Get Verrify Attendee list Failed",
  GET_VERIFY_ATTENDEE_LIST_SUCCESS:
    "[GET_VERIFY_ATTENDEE_LIST_SUCCESS][RESPONSE] Get  Verrify Attendee list Successfull",
  VERIFY_LIST_PAGE: "VerrifyUserListPage",

  DELETE_SCHEDULE_TOOL_BOX_TALK_PENDING:
    "DELETE_SCHEDULE_TOOL_BOX_TALK_PENDING",
  DELETE_SCHEDULE_TOOL_BOX_TALK_SUCCESS:
    "DELETE_SCHEDULE_TOOL_BOX_TALK_SUCCESS",
  DELETE_SCHEDULE_TOOL_BOX_TALK_FAILED: "DELETE_SCHEDULE_TOOL_BOX_TALK_FAILED",
  CLEAR_SCHEDULE_TOOLTALK:
    "[CLEAR_TOOLTALK][REQUEST] Clear  Schedule TOOL TALK",
  TOOLBOXTALK_SCHEDULE_DELETE_PAGE: "ToolboxtalkDeleteSchedulePage",

  POST_USER_MARKED_TOOL_BOX_TALK_PENDING:
    "[POST_USER_MARKED_TOOL_BOX_TALK_PENDING][REQUEST] User Marked Tool Box TALK",
  POST_USER_MARKED_TOOL_BOX_TALK_FAILURE:
    "[POST_USER_MARKED_TOOL_BOX_TALK_FAILURE][RESPONSE] User Marked Creation Failed",
  POST_USER_MARKED_TOOL_BOX_TALK_SUCCESS:
    "[POST_USER_MARKED_TOOL_BOX_TALK_SUCCESS][RESPONSE] User Marked Created Successfull",
  CLEAR_USERTOOLTALK: "[CLEAR_USERTOOLTALK][REQUEST] Clear USER TOOL TALK",

  CREATE_USER_MARKEDPAGE: "CreateUsermarkedToolBoxTalkPage",

  GET_EXTRA_USER_LIST_PENDING:
    "[GET_EXTRA_USER_LIST][REQUEST] Extra User List requested",
  GET_EXTRA_USER_LIST_FAILURE:
    "[GET_EXTRA_USER_LIST][RESPONSE] Extra User List  Failed",
  GET_EXTRA_USER_LIST_SUCCESS:
    "[GET_EXTRA_USER_LIST][RESPONSE] Extra User List  Successfull",
  GET_EXTRA_USER_PAGE: "Extra User List Page ",

  POST_EXTRA_USER_PENDING: "[EXTRA_USER][REQUEST] Create EXTRA_USER",
  POST_EXTRA_USER_FAILURE: "[EXTRA_USER][RESPONSE] EXTRA_USER Creation Failed",
  POST_EXTRA_USER_SUCCESS:
    "[EXTRA_USER][RESPONSE] EXTRA_USER Created Successfull",
  CLEAR_CREATE_USER_EXTRA:
    "[CLEAR_CREATE_USER_EXTRA][REQUEST] Clear CLEAR_CREATE_USER_EXTRA",
  CREATE_EXTRA_USER_PAGE: "CREATE EXTRA USER PAGE",

  GET_PROJECT_SEARCH_LIST_PENDING:
    "[GET_PROJECT_SEARCH_LIST_PENDING][REQUEST] Get Project Search list requested",
  GET_PROJECT_SEARCH_LIST_FAILURE:
    "[GET_PROJECT_SEARCH_LIST_FAILURE][RESPONSE]Get Project Search list requested list Failed",
  GET_PROJECT_SEARCH_LIST_SUCCESS:
    "[GET_PROJECT_SEARCH_LIST_SUCCESS][RESPONSE] Get Project Search list requested list Successfull",
  PROJECT_SEARCH_PAGE: "ProjectSearchPage",

  GET_ALL_USER_SEARCH:
    "[REQUEST GET_ALL_USER_SEARCH] Search all user Project wise  Data",
  GET_ALL_USER_SEARCH_SUCCESS:
    "[RESPONSE GET_ALL_USER_SEARCH_SUCCESS] Seach all user Data  Project wise Successfull",
  GET_ALL_USER_SEARCH_FAILURE:
    "[RESPONSE GET_ALL_USER_SEARCH_FAILURE] Seacrh all user Data  Project wise Failed",
  USER_PROJECT_SEARCH_PAGE: "UserProjectSearchPage",
  CLEAR_USERPROJECTWISETALK: "CLEAR_USERPROJECTWISETALK",

  SCHEDULE_USERCOUNT_STATUS_LIST_PENDING:
    "[SCHEDULE_USERCOUNT_STATUS_LIST_PENDING][REQUEST] Get Schedule User Count Status list requested",
  SCHEDULE_USERCOUNT_STATUS_LIST_FAILURE:
    "[SCHEDULE_USERCOUNT_STATUS_LIST_FAILURE][RESPONSE] Get Schedule User Count Status list Failed",
  SCHEDULE_USERCOUNT_STATUS_LIST_SUCCESS:
    "[SCHEDULE_USERCOUNT_STATUS_LIST_SUCCESS][RESPONSE] Get Schedule User Count Status list Successfull",
  SCHEDULE_USERCOUNT_STATUS_LIST_CLEAR:
    "[SCHEDULE_USERCOUNT_STATUS_LIST_SUCCESS][RESPONSE] Get Schedule User Count Status list Clear",
  SCHEDULE_USER_COUNTPAGE: "ScheduleUserCountPage",

  POST_SAFETY_MEETING_ALERT_PENDING:
    "[POST_SAFETY_MEETING_ALERT_PENDING][REQUEST] post safety meeting alert mail requested",
  POST_SAFETY_MEETING_ALERT_FAILURE:
    "[POST_SAFETY_MEETING_ALERT_FAILURE][RESPONSE] post safety meeting alert mail Failed",
  POST_SAFETY_MEETING_ALERT_SUCCESS:
    "[POST_SAFETY_MEETING_ALERT_SUCCESS][RESPONSE] post safety meeting alert mail Successfull",
  ASSIGN_SAFETY_MEETING_ALERT_PAGE: "ASSIGN_SAFETY_MEETING_ALERT_PAGE",

  GET_MY_SAFETY_MEETING_PENDING:
    "[GET_MY_SAFETY_MEETING_PENDING][REQUEST] Get My Safety Meeting list requested",
  GET_MY_SAFETY_MEETING_FAILURE:
    "[GET_MY_SAFETY_MEETING_FAILURE][RESPONSE] Get My Safety Meeting list requested Failed",
  GET_MY_SAFETY_MEETING_SUCCESS:
    "[GET_MY_SAFETY_MEETING_SUCCESS][RESPONSE] Get My Safety Meeting list requested Successfull",
  MYSAFETYMEETING_PAGE: "MySAfetyMeetingPage",
  GET_SCHEDULE_INSTRUCTOR_DETAILS:
    "[REQUEST GET_SCHEDULE_INSTRUCTOR_DETAILS] getScheduleInstructorDetails  Data",
  GET_SCHEDULE_INSTRUCTOR_DETAILS_SUCCESS:
    "[RESPONSE GET_SCHEDULE_INSTRUCTOR_DETAILS_SUCCESS] getScheduleInstructorDetails Successfull",
  GET_SCHEDULE_INSTRUCTOR_DETAILS_FAILURE:
    "[RESPONSE GET_SCHEDULE_INSTRUCTOR_DETAILS_FAILURE] getScheduleInstructorDetails wise Failed",

  GET_SCHEDULE_ATTENDEES_DETAILS:
    "[REQUEST GET_SCHEDULE_ATTENDEES_DETAILS] getScheduleATTENDEESDetails  Data",
  GET_SCHEDULE_ATTENDEES_DETAILS_SUCCESS:
    "[RESPONSE GET_SCHEDULE_ATTENDEES_DETAILS_SUCCESS] getScheduleATTENDEESDetails Successfull",
  GET_SCHEDULE_ATTENDEES_DETAILS_FAILURE:
    "[RESPONSE GET_SCHEDULE_ATTENDEES_DETAILS_FAILURE] getScheduleATTENDEESDetails wise Failed",

  GET_SCHEDULE_SAFETY_MEETING_DETAILS:
    "[REQUEST GET_SCHEDULE_SAFETY_MEETING_DETAILS] getScheduleSAFETY_MEETINGDetails  Data",
  GET_SCHEDULE_SAFETY_MEETING_DETAILS_SUCCESS:
    "[RESPONSE GET_SCHEDULE_SAFETY_MEETING_DETAILS_SUCCESS] getScheduleSAFETY_MEETINGDetails Successfull",
  GET_SCHEDULE_SAFETY_MEETING_DETAILS_FAILURE:
    "[RESPONSE GET_SCHEDULE_SAFETY_MEETING_DETAILS_FAILURE] getScheduleSAFETY_MEETINGDetails wise Failed",

  POST_UPDATE_ADMIN_ASSIGN_INSTRUCTOR:
    "[REQUEST POST_UPDATE_ADMIN_ASSIGN_INSTRUCTOR] UpdateAdminAssignInstructors  Data",
  POST_UPDATE_ADMIN_ASSIGN_INSTRUCTOR_SUCCESS:
    "[RESPONSE POST_UPDATE_ADMIN_ASSIGN_INSTRUCTOR_SUCCESS] UpdateAdminAssignInstructors Successfull",
  POST_UPDATE_ADMIN_ASSIGN_INSTRUCTOR_FAILURE:
    "[RESPONSE POST_UPDATE_ADMIN_ASSIGN_INSTRUCTOR_FAILURE] UpdateAdminAssignInstructors wise Failed",
  POST_DATA_CLEAR: "Data clear",
  GET_MY_MEETING_DATA_CLEAR: "Data clear my safety meeting",
};
