import React, { useEffect, useState } from "react";
import ContractorConfigurationList from "../component/users/contractor/contractorConfigurationList";
import * as contractorAction from "../../../redux/actions/contractorAction";
import * as userAction from "../../../redux/actions/userAction";
import { API_STATUS, ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { selectOnboardContractorStatus } from "../../../redux/selectors/adminSelector";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";

function ConrtactorConfigurationListContainer() {
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const onboardContractorStatus = useSelector(selectOnboardContractorStatus);

  const updateOnboardedContractorListData = useSelector(
    (state) => state.updateOnboardedContractorListData
  );
  const updateOnboardedContractorListDataStatus = useSelector(
    (state) => state.updateOnboardedContractorListData.status
  );
  const isLoading = updateOnboardedContractorListData.isLoading;

  const OnboardedContractorListData = useSelector(
    (state) => state.getOnboardedContractorListData
  );
  const recreateUsersReducer = useSelector((state) => state.recreateUsersData);
  const recreateUsersReducerstatus = useSelector(
    (state) => state.recreateUsersData.status
  );
  const showLoader = recreateUsersReducer.isLoading;

  const getOnboardedContractorLists = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(contractorAction.getOnboardedContractorList(data));
  };
  const defaultData = {
    page: 1,
    search: "",
    pageSize: PAGE_SIZE,
    Status: "",
    isSendMail: false,
  };

  const dropdownDataKeyValueParams = {
    orgId: ORG_ID(),
    flags: "21",
    productId: PRODUCT_ID(),
  };

  useEffect(() => {
    console.log("QA DPL USER CHECK");
    setIsSuccess(false);
    dispatch(adminAction.getDropdownDataKeyValue(dropdownDataKeyValueParams));
    getOnboardedContractorLists(defaultData);
  }, []);

  useEffect(() => {
    if (
      updateOnboardedContractorListDataStatus === API_STATUS.SUCCESS &&
      updateOnboardedContractorListData?.isLoading === false
    ) {
      setIsSuccess(false);
      setTimeout(() => {
        window.location.reload();
      }, 3800);
    }
  }, [updateOnboardedContractorListDataStatus]);

  useEffect(() => {
    if (
      recreateUsersReducerstatus === API_STATUS.SUCCESS &&
      recreateUsersReducer?.isLoading === false
    ) {
      setIsSuccess(false);
      setTimeout(() => {
        window.location.reload();
      }, 3800);
    }
  }, [recreateUsersReducerstatus]);

  const updateOnboardedContractorList = (data) => {
    setIsSuccess(true);
    if (data.contractorIds.length == 0) {
      toast.error("Please Select Contractor");
      return false;
    }
    dispatch(contractorAction.updateOnboardedContractorList(data));
  };

  const recreateUsersList = (data) => {
    setIsSuccess(true);
    dispatch(userAction.recreateUsers(data));
  };

  useEffect(() => {
    if (
      OnboardedContractorListData?.status === API_STATUS.SUCCESS &&
      sendEmail
    ) {
      toast("Onboarded contractors has been sent to registered email ID");
    }
  }, [OnboardedContractorListData]);

  return (
    <>
      {showLoader && <Loader />}
      {isLoading && <Loader />}
      {updateOnboardedContractorListDataStatus === API_STATUS.SUCCESS &&
        updateOnboardedContractorListData?.data &&
        isSuccess &&
        toast("Contractor Added Successfully")}
      {recreateUsersReducerstatus === API_STATUS.SUCCESS &&
        isSuccess &&
        toast("Welcome Email Send Successfully")}
      <div>
        <ToastContainer />
      </div>
      <ContractorConfigurationList
        getOnboardedContractorLists={getOnboardedContractorLists}
        OnboardedContractorListData={OnboardedContractorListData}
        loading={OnboardedContractorListData.isLoading}
        updateOnboardedContractorList={updateOnboardedContractorList}
        recreateUsersList={recreateUsersList}
        onboardContractorStatus={onboardContractorStatus}
      />
    </>
  );
}

export default ConrtactorConfigurationListContainer;
