import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { get } from "./axiosRequest";

export const getSequenceLandingProjectPermitApi = async ({ data }) => {
  const { page, pageSize, search, projectId } = data;
  const url = `${
    getApiUrl().sequenceLandingListUrl
  }GetPermitList?page=${page}&pageSize=${pageSize}&projectId=${
    projectId ? projectId : 0
  }&search=${search ? search : ""}`;
  console.log("dffsf", url);
  try {
    return await get(url, {
      headers: {},
    });
  } catch (err) {
    return err;
  }
};

export const getSequenceLandingPermitDetailApi = async ({ data }) => {
  const { permitId } = data;

  const url = `${
    getApiUrl().sequenceLandingListUrl
  }GetPermitDetail?permitId=${permitId}`;

  try {
    return await get(url, {
      headers: {},
    });
  } catch (err) {
    return err;
  }
};

// Inspection

export const getSequenceLandingInspectionsdata = async ({ data }) => {
  const { search, projectId, page, pageSize } = data;

  const url = `${
    getApiUrl().sequenceLandingListUrl
  }GetInspectionList?search=${search}&projectId=${projectId}&page=${page}&pageSize=${pageSize}`;
  try {
    return await get(url, {
      headers: {},
    });
  } catch (err) {
    return err;
  }
};

export const getSequenceLandingInspectionsFieldTypedata = async ({ data }) => {
  const { projectId, InspectionTypeId } = data;

  const url = `${
    getApiUrl().sequenceLandingListUrl
  }GetInspectionTypeFieldsByInspectionType?InspectionTypeId=${InspectionTypeId}&projectId=${projectId}`;
  try {
    return await get(url, {
      headers: {},
    });
  } catch (err) {
    return err;
  }
};

export const getSequenceLandingInspectionsDetailsdata = async ({ data }) => {
  const { InspectionId } = data;

  const url = `${
    getApiUrl().sequenceLandingListUrl
  }GetInspectionReportView?InspectionId=${InspectionId}`;
  try {
    return await get(url, {
      headers: {},
    });
  } catch (err) {
    return err;
  }
};

// Resource

export const getSequenceLandingResourceList = async ({ data }) => {
  const { assetId, assetTypeId, search, page, pageSize } = data;

  const url = `${
    getApiUrl().sequenceLandingListUrl
  }GetResourcesList?assetId=${assetId}&assetTypeId=${assetTypeId}&search=${search}&page=${page}&pageSize=${pageSize}`;
  try {
    return await get(url, {
      headers: {},
    });
  } catch (err) {
    return err;
  }
};

export const getSequenceLandingSamplingLogs = async ({ data }) => {
  const { page, pageSize, search, projectId } = data;
  const url = `${
    getApiUrl().sequenceLandingListUrl
  }GetSampleLogList?page=${page}&pageSize=${pageSize}&projectId=${
    projectId ? projectId : 0
  }&search=${search ? search : ""}`;
  console.log("dffsf", url);
  try {
    return await get(url, {
      headers: {},
    });
  } catch (err) {
    return err;
  }
};