import { FormControl, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReportDetail from "./reportDetail";
import Button from "../../../library/custom/button/Button";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import { InputField } from "../../../library/custom/textBox/InputField";
import { getEstablishmentTypeValue } from "../../../utils/helper";
import { ESTABLISHMENT_TYPE, USER_ID } from "../../../constant/constant";
import { NumberField } from "../../../library/custom/numberField/numberField";
import { checkPrivileges } from "../../../utils/rolesHelper";
import Na from "./na";
import { useThemeContext } from "../../../context";
import NORow from "../../../library/custom/noRow/noRow";

const OshaItaSubmission = (props) => {
  const [isEstablishmentDetailValid, setIsEstablishmentDetailValid] =
    useState(false);

  const handleYearChange = (event) => {
    props?.setYear(event.target.value);
  };
  const handleEstablishmentChange = (event) => {
    props?.setEstablishmentId(event.target.value);
  };
  const [errors, setErrors] = useState({});
  const [numberOfEmployees, setNumberOfEmployees] = useState();
  const [hoursWorked, setHoursWorked] = useState();
  const [numberOfEmployeesError, setNumberOfEmployeesError] = useState(false);
  const [hoursWorkedError, setHoursWorkedError] = useState(false);
  const { mode } = useThemeContext();

  const handleEmpChange = (e) => {
    setNumberOfEmployees(e);
  };
  const handleHwChange = (e) => {
    setHoursWorked(e?.target?.value);
  };
  const validate = () => {
    const newErrors = {};
    if (!props?.establishmentId) {
      newErrors.establishmentError = "Establishment is required.";
    }
    if (!props?.year) {
      newErrors.yearError = "Year is required.";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    const validationErrors = validate();
    if (!numberOfEmployees) {
      setNumberOfEmployeesError(true);
      validationErrors.numberOfEmployeesError =
        "Number of Employees is required.";
    } else {
      setNumberOfEmployeesError(false);
    }
    if (!hoursWorked) {
      setHoursWorkedError(true);
      validationErrors.hoursWorkedError = "Hours Worked is required.";
    } else {
      setHoursWorkedError(false);
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = {
      caseNumberOfDeath: props?.oshaDetail?.caseNumberOfDeath,
      caseNumberOfDaysAway: props?.oshaDetail?.caseNumberOfDaysAway,
      caseNumberOfTransfer: props?.oshaDetail?.caseNumberOfTransfer,
      caseNumberOtherRecordable: props?.oshaDetail?.caseNumberOtherRecordable,
      numberOfDaysAway: props?.oshaDetail?.numberOfDaysAway,
      numberOfTransfer: props?.oshaDetail?.numberOfTransfer,
      injuries: props?.oshaDetail?.injuries,
      skinDisorder: props?.oshaDetail?.skinDisorder,
      respiratoryCondition: props?.oshaDetail?.respiratoryCondition,
      poisoning: props?.oshaDetail?.poisoning,
      hearingLoss: props?.oshaDetail?.hearingLoss,
      otherIllnesses: props?.oshaDetail?.otherIllnesses,
      establishmentId: props?.establishmentId,
      year: props?.year?.toString(),
      annualAvgNumberOfEmployee: parseInt(numberOfEmployees),
      totalEmployeeHoursWorked: parseInt(hoursWorked),
      userId: parseInt(USER_ID()),
    };

    props?.addOshaSubmissionHandler(data);
  };

  useEffect(() => {
    if (props?.establishmentDetail) {
      const isEstablishmentDetailValid =
        props?.establishmentDetail?.establishmentId &&
          props?.establishmentDetail?.street &&
          props?.establishmentDetail?.city &&
          props?.establishmentDetail?.state &&
          props?.establishmentDetail?.zipCode &&
          props?.establishmentDetail?.naics && props?.establishmentDetail?.ein
          ? false
          : true;
      setIsEstablishmentDetailValid(isEstablishmentDetailValid);
    }
  }, [props?.establishmentDetail]);

  useEffect(() => {
    if (props?.oshaDetail) {
      setNumberOfEmployees(props?.oshaDetail?.avgNumberOfEmployees);
      setHoursWorked(props?.oshaDetail?.totalManHours);
    }
  }, [props?.oshaDetail]);

  return (
    <>
      <Grid container className="overrideViewReports">
        <Grid container mb={3}>
          <Grid item container spacing={2} md={12} xs={12}>
            <Grid item lg={6} xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FormLabels label="Year Submitting For" isRequired={true} />
                <SelectMenu
                  placeholder="Please Select"
                  listData={props?.years ?? []}
                  value={props?.year}
                  name="year"
                  onchangehandler={handleYearChange}
                  disabled={props?.submitFromRecordkeeping}
                />
                {errors.yearError && (
                  <div className="errorMsg">{errors.yearError}</div>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <FormLabels label="Establishment" isRequired={true} />
                <SelectMenu
                  placeholder="Please Select"
                  listData={props?.establishments}
                  value={props?.establishmentId ?? ""}
                  name="establishmentId"
                  onchangehandler={handleEstablishmentChange}
                  disabled={props?.submitFromRecordkeeping}
                />
                {errors.establishmentError && (
                  <div className="errorMsg">{errors.establishmentError}</div>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <>
          {props?.establishmentId && props?.year && (
            <Grid container>
              <Grid item md={12} xs={12}>
                <div className="viewReportHeaderContainerBottom box-shadow mt-10">
                  <div className="vrhctListBox">
                    <div className="vrhctList  w-100">
                      <div className="vrhctListItem">
                        Validate Establishment Details
                      </div>
                      {/* {checkPrivileges([12, 152, 153]) && (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            props?.editEstablishment(
                              props?.establishmentDetail?.establishmentId
                            )
                          }
                        >
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 20 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 22V18H20V22H0ZM2 16V11.75L13.2 0.575C13.3833 0.391667 13.5958 0.25 13.8375 0.15C14.0792 0.05 14.3333 0 14.6 0C14.8667 0 15.125 0.05 15.375 0.15C15.625 0.25 15.85 0.4 16.05 0.6L17.425 2C17.625 2.18333 17.7708 2.4 17.8625 2.65C17.9542 2.9 18 3.15833 18 3.425C18 3.675 17.9542 3.92083 17.8625 4.1625C17.7708 4.40417 17.625 4.625 17.425 4.825L6.25 16H2ZM14.6 4.8L16 3.4L14.6 2L13.2 3.4L14.6 4.8Z"
                              fill={mode === "dark" ? "#004ddd" : "#1A446F"}
                            />
                          </svg>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="vrhctListBox">
                    <div className="vrhctList  w-100">
                      <div className="vrhctListItem">
                        Establishment:
                        <span>
                          {props?.establishmentDetail?.establishmentName ? (
                            props?.establishmentDetail?.establishmentName
                          ) : (
                            <Na />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox">
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        Street:
                        <span>
                          {props?.establishmentDetail?.street ? (
                            props?.establishmentDetail?.street
                          ) : (
                            <Na />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        City:{" "}
                        <span>
                          {props?.establishmentDetail?.city ? (
                            props?.establishmentDetail?.city
                          ) : (
                            <Na />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox border-none">
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        State:
                        <span>
                          {props?.establishmentDetail?.state ? (
                            props?.establishmentDetail?.state
                          ) : (
                            <Na />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="vrhctList">
                      <div className="vrhctListItem">
                        Zip Code:
                        <span>
                          {props?.establishmentDetail?.zipCode ? (
                            props?.establishmentDetail?.zipCode
                          ) : (
                            <Na />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox">
                    <div className="vrhctList  w-100">
                      <div className="vrhctListItem">
                        Industry Description:
                        <span>
                          {props?.establishmentDetail?.industryDescription}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="vrhctListBox">
                    <div className="vrhctList w-100">
                      <div className="vrhctListItem">
                        EIN:
                        {props?.establishmentDetail?.ein ? (
                          props?.establishmentDetail?.ein
                        ) : (
                          <Na />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox">
                    <div className="vrhctList w-100">
                      <div className="vrhctListItem">
                        Establishment Type:
                        <span>
                          {getEstablishmentTypeValue(
                            props?.establishmentDetail?.establishmentType,
                            ESTABLISHMENT_TYPE
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox">
                    <div className="vrhctList w-100">
                      <div className="vrhctListItem">
                        Standard Industrial Classification (SIC):
                        <span>{props?.establishmentDetail?.sic}</span>
                      </div>
                    </div>
                  </div>
                  <div className="vrhctListBox border-none">
                    <div className="vrhctList w-100">
                      <div className="vrhctListItem">
                        North American Industrial Classification (NAICS):
                        <span>
                          {props?.establishmentDetail?.naics ? (
                            props?.establishmentDetail?.naics
                          ) : (
                            <Na />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}

          {props?.establishmentId && props?.year && props?.showDetail && (
            <>
              <Grid container mb={3}>
                <Grid item container spacing={2} md={12} xs={12}>
                  <Grid item lg={6} xs={12} sm={6} md={6}>
                    <NumberField
                      isRequired={true}
                      type="text"
                      label="Average Number of Employees"
                      name="numberofemployee"
                      placeholder={"Add Average Number of Employees Here..."}
                      value={numberOfEmployees}
                      onChange={handleEmpChange}
                      disabled={true}
                    />
                    {numberOfEmployeesError && !numberOfEmployees && (
                      <div className="errorMsg">
                        {"Average Number of Employees is Required"}
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={6} xs={12} sm={6} md={6}>
                    <InputField
                      isRequired={true}
                      type="text"
                      label="Total Man Hours"
                      name="hoursworked"
                      placeholder={"Add Total Man Hours Here..."}
                      value={hoursWorked}
                      onChange={handleHwChange}
                      disabled={true}
                    />
                    {hoursWorkedError && !hoursWorked && (
                      <div className="errorMsg">
                        {"Total Man Hours is Required"}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={12} xs={12}>
                  <ReportDetail reportData={props?.oshaDetail} />
                </Grid>
              </Grid>

              <Grid container mt={3}>
                <Grid container item lg={12} gap={12} alignItems="center">
                  <Grid
                    item
                    lg={3}
                    xs={12}
                    textAlign="center"
                    className="addcourseButton"
                  >
                    {checkPrivileges([12, 153, 152]) && (
                      <Button
                        varientContained={true}
                        label={"Submit"}
                        onClick={handleSubmit}
                        disabled={isEstablishmentDetailValid}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {props?.establishmentId && props?.year && !props?.showDetail && (
            <Grid container>
              <Grid item md={12} xs={12}>
                <div className="noDataMessage">
                  <div className="noDataMessageTitle">
                    Your incident forms are missing required information to
                    submit your OSHA ITA. Review them in OSHA Recordkeeping.
                  </div>
                  <div className="backbtn" onClick={props?.backHandler}>Back to OSHA Recordkeeping </div>
                  <NORow />
                </div>
              </Grid>
            </Grid>
          )}
        </>
      </Grid>
    </>
  );
};

export default OshaItaSubmission;
