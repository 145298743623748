import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import "./toolBoxTalkList.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "../tabPanel/tabPanel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TOOL_BOX_DETAIL } from "../../../../constant/routeContant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import { ORG_ID, USER_ID } from "../../../../constant/constant";
import SearchIcon from "@mui/icons-material/Search";
import TableCardView from "../../../../library/custom/tableCardView/tableCardView";
import SafetyMeetingTableCard from "../../../../library/custom/tableCard/safetyMeetingTableCard";
import FilterCommonStatusSelect from "../../../admin/shared/FilterCommonStatusSelect";
import FilterListIcon from "@mui/icons-material/FilterList";
import { getFormatedDate } from "../../../../utils/helper";

const ToolBoxTalkUserList = (props) => {
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 6;

  const getAdminToolBoxTalkListData = props?.getAdminToolBoxTalkListData;
  const [value, setValue] = React.useState(0);
  const [search, setSearch] = useState("");
  const getToolBoxTalkListResult = props?.getToolBoxTalkListResult;
  const getToolBoxTalkListData = props?.getToolBoxTalkListData;
  const categoryListData = props?.categoryListData;
  const noRecourdCount = getToolBoxTalkListResult?.itemsCount;
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.mySafetyMeetingList);
  const [selectedValue, setSelectedValue] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isSafetyLog = props?.isSafetyLog;
  const isProfile = props?.isProfile;
  const usersId = props?.usersId;
  const isShow = props?.isShow;
  const handleChange = (event, newValue) => {
    setPage(1);
    setValue(newValue);
    if (newValue === 2) {
      setFilter({
        ...filter,
        fromDate: "",
        toDate: "",
      });
    }
    if (newValue === 2) {
      setValue(newValue);
      const data = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: search,
        userId: "",
        organisationId: ORG_ID(),
        tabId: value === 1 ? "0" : "1",
        categoryIds: selectedValue,
        fromDate: "",
        toDate: "",
      };
      props.getToolBoxTalkListAdmin(data);
    } else {
      const data = {
        page: 1,
        pageSize: PAGE_SIZE,
        search: search,
        userId: isSafetyLog ? usersId : USER_ID(),
        tabId:
          newValue === 0 ? 2 : newValue === 1 ? 3 : newValue === 2 ? 4 : "",
        CatId: selectedValue ? selectedValue : "",
        fromDate: newValue === 2 ? "" : filter.fromDate,
        toDate: newValue === 2 ? "" : filter.toDate,
        tabId:
          newValue === 0 ? 2 : newValue === 1 ? 3 : newValue === 2 ? 4 : "",
      };

      getToolBoxTalkListData(data);
    }
  };

  const handleViewOnClick = (val) => {
    const safetyDetail = val;
    const toolboxTalkVersionId = val?.row?.toolboxTalkVersionId;
    navigate(TOOL_BOX_DETAIL, {
      state: {
        safetyDetail: safetyDetail?.row,
        toolboxtalkuyserlist: true,
        toolboxTalkVersionId: toolboxTalkVersionId,
        isCurrentActiveContent: false,
        toolboxTalkId: value === 2 ? val?.row?.id : val?.row?.toolboxTalkId,
        instructorUserId: val?.row?.instructorUserId,
        scheduleId: val?.row?.scheduleId,
        fromMyTraining: true,
        fromCompletedTab: value === 1 ? true : false,
        showDownloadBtn: value === 1 ? true : false,
        fromAssignedTab: value === 0 ? true : false,
      },
    });
  };
  const columns1 = [
    {
      field: "safetyId",
      width: "fit-content",
      cellClassName: "customCellTable custom",
      renderCell: (cellValues) => {
        return (
          <>
            <SafetyMeetingTableCard
              viewOnClick={() => handleViewOnClick(cellValues)}
              rowData={cellValues?.row}
              tab={value}
            />
          </>
        );
      },
    },
  ];
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);

    if (value === 2) {
      const data = {
        pageNumber: newPage + 1,
        pageSize: PAGE_SIZE,
        search: search,
        userId: "",
        organisationId: ORG_ID(),
        tabId: value === 1 ? "0" : "1",
        categoryIds: selectedValue,
        fromDate: "",
        toDate: "",
      };
      props.getToolBoxTalkListAdmin(data);
    } else
      getToolBoxTalkListData({
        page: newPage + 1,
        pageSize: PAGE_SIZE,
        search: search,
        userId: isSafetyLog ? usersId : USER_ID(),
        tabId: value === 0 ? 2 : value === 1 ? 3 : value === 2 ? 4 : "",
        CatId: selectedValue ? selectedValue : "",
        fromDate: filter.fromDate,
        toDate: filter.toDate,
      });
  };

  const handleSearch = (data1) => {
    setAnchorEl(null);

    if (value === 2) {
      const data = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: search,
        userId: "",
        organisationId: ORG_ID(),
        tabId: value === 1 ? "0" : "1",
        categoryIds: selectedValue,
        fromDate: "",
        toDate: "",
      };
      props.getToolBoxTalkListAdmin(data);
    } else {
      const data = {
        page: 1,
        pageSize: PAGE_SIZE,
        search: search,
        userId: isSafetyLog ? usersId : USER_ID(),
        tabId: value === 0 ? 2 : value === 1 ? 3 : value === 2 ? 4 : "",
        CatId: selectedValue ? selectedValue : "",
        fromDate: filter.fromDate,
        toDate: filter.toDate,
      };

      getToolBoxTalkListData(data);
    } // setOpen(false);
  };
  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);

    if (value === 2) {
      const data = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: searchText ? searchText : "",
        userId: "",
        organisationId: ORG_ID(),
        tabId: value === 1 ? "0" : "1",
        categoryIds: selectedValue,
        fromDate: "",
        toDate: "",
      };
      props.getToolBoxTalkListAdmin(data);
    } else {
      const data = {
        page: 1,
        pageSize: PAGE_SIZE,
        search: searchText ? searchText : "",
        userId: isSafetyLog ? usersId : USER_ID(),
        tabId: value === 0 ? 2 : value === 1 ? 3 : value === 2 ? 4 : "",
        CatId: selectedValue ? selectedValue : "",
        fromDate: filter.fromDate,
        toDate: filter.toDate,
      };
      getToolBoxTalkListData(data);
    }
  };
  const onChangeCategoryType = (item) => {
    setSelectedValue(item.target.value);
  };
  getToolBoxTalkListResult?.saftyMeetingList?.map((Dta, index) => {
    return (Dta["safetyid"] = (page - 1) * 5 + index + 1);
  });
  const itemsCount = getToolBoxTalkListResult?.itemsCount;
  const data = getToolBoxTalkListResult?.saftyMeetingList;

  data?.forEach((item) => {
    item.id = item?.scheduleId;
  });
  const handleClear = (item) => {
    setSelectedValue("");
    setSearch("");
    setPage(1);
    setFilter({
      ...filter,
      fromDate: "",
      toDate: "",
    });

    if (value === 2) {
      const data = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        search: "",
        userId: "",
        organisationId: ORG_ID(),
        tabId: value === 1 ? "0" : "1",
        categoryIds: "",
        fromDate: "",
        toDate: "",
      };
      props.getToolBoxTalkListAdmin(data);
    } else
      getToolBoxTalkListData({
        page: 1,
        pageSize: PAGE_SIZE,
        search: "",
        userId: isSafetyLog ? usersId : USER_ID(),
        tabId: value === 0 ? 2 : value === 1 ? 3 : value === 2 ? 4 : "",
        CatId: "",
        fromDate: "",
        toDate: "",
      });
    setAnchorEl(null);
    // setOpen(false);
  };

  const [filter, setFilter] = useState({
    searchType: "",
    toDate: "",
    fromDate: "",
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const onChangeToDate = (item) => {
    setFilter({
      ...filter,
      toDate: getFormatedDate(item),
    });
  };
  const onChangeFromDate = (item) => {
    setFilter({
      ...filter,
      fromDate: getFormatedDate(item),
    });
  };
  return (
    <>
      <div className="toolboxList">
        <Grid container>
          <Grid item md={12}>
            <h1>Safety Meetings</h1>
          </Grid>
        </Grid>
        {/* {isShow || isShow == undefined ? ( */}
        <>
          <Grid container mt={3}>
            <Grid lg={7} xs={12}>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="scrollable auto tabs example"
                  variant="scrollable"
                  scrollButtons="none"
                >
                  <Tab
                    className="tabcustom"
                    icon={
                      value === 0 ? (
                        <div className="totalV">
                          {value === 0 ? noRecourdCount : ""}
                        </div>
                      ) : (
                        ""
                      )
                    }
                    iconPosition="end"
                    label="Assigned Meetings"
                  />

                  <Tab
                    className="tabcustom"
                    icon={
                      value === 1 ? (
                        <div className="totalV">
                          {value === 1 ? noRecourdCount : ""}
                        </div>
                      ) : (
                        ""
                      )
                    }
                    iconPosition="end"
                    label="Completed Meetings"
                  />
                  {isSafetyLog || isProfile ? (
                    ""
                  ) : (
                    <Tab
                      className="tabcustom"
                      icon={
                        value === 2 ? (
                          <div className="totalV">
                            {value === 2 ? getAdminToolBoxTalkListData?.itemsCount ?? 0 : ""}
                          </div>
                        ) : (
                          ""
                        )
                      }
                      iconPosition="end"
                      label="All meetings"
                    />
                  )}
                </Tabs>
              </Box>
            </Grid>
            <Grid lg={5} xs={12}>
              <div className="filtertoolbox">
                <div className="filter_of_caselisttoolBox">
                  <Grid container className="safetyuserSearchFilter">
                    {/* <Grid lg={8} xs={12}> */}
                    <div className="searchcaseid">
                      <label htmlFor="search">
                        <span>
                          <SearchIcon />
                        </span>
                        <div className="searchfilterInput">
                          <input
                            id="search"
                            placeholder="Search"
                            type="text"
                            value={search}
                            className=""
                            onChange={handleautoSearch}
                            autoComplete="off"
                          />
                        </div>
                      </label>
                    </div>
                    {/* </Grid> */}

                    <div className="filter_btn">
                      <button
                        aria-describedby={id}
                        variant="contained"
                        type="button"
                        name="btn"
                        onClick={(e) => handleClick(e)}
                      >
                        <FilterListIcon id="icn" /> Filters
                      </button>
                      <FilterCommonStatusSelect
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        handleApply={handleSearch}
                        handleClear={handleClear}
                        filterDate={value !== 2}
                        filterSelect
                        filter={filter}
                        categoryType={categoryListData ?? []}
                        categoryOnChanges={onChangeCategoryType}
                        category={selectedValue}
                        filterStatus={false}
                        resetBtn={true}
                        label={"Category"}
                        fromDate={filter.fromDate}
                        onchangeFromDatePicker={onChangeFromDate}
                        toDate={filter.toDate}
                        onchangeToDatePicker={onChangeToDate}
                      />
                    </div>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </>

        {/* ) : (
          ""
        )} */}

        <Grid container>
          <TabPanel className="tabPanel" value={value} index={0}>
            <TableCardView
              columns={columns1}
              rows={data ?? []}
              rowCount={itemsCount ?? 0}
              loading={isLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              rowsPerPageOptions={[10]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
            />
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={1}>
            <TableCardView
              columns={columns1}
              rows={data ?? []}
              rowCount={itemsCount ?? 0}
              loading={isLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              rowsPerPageOptions={[10]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
            />
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={2}>
            <TableCardView
              columns={columns1}
              rows={getAdminToolBoxTalkListData?.saftyMeetingList ?? []}
              rowCount={getAdminToolBoxTalkListData?.itemsCount ?? 0}
              loading={props?.getAdminToolBoxTalkListDataLoading}
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
              rowsPerPageOptions={[10]}
              page={page}
              pagination
              pageSize={PAGE_SIZE}
              onPageChange={handlePagignation}
            />
          </TabPanel>
        </Grid>
      </div>
    </>
  );
};

export default ToolBoxTalkUserList;
